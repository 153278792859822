import { useSubscription } from 'react-stomp-hooks'

import { useAppDispatch, useAppSelector } from '../../store/hooks/hook'
import { useApi } from '../../store/hooks/request'
import { userSelector } from '../../store/selectors/user'
import {
  setCampaign,
  setCurrentCampaign,
} from '../../store/slices/campaignSlice'
import {
  setCallState,
  setCallStart,
  setCurrentScript,
  setCustomer,
  setExpanded, setPreviewCallTimer,
} from '../../store/slices/scriptSlice'
import { CallScriptResponse } from '../../types'

export default function useCallStateChange(authorizationStatus: boolean) {
  const { api } = useApi()

  const { agentId } = useAppSelector(userSelector)
  const dispatch = useAppDispatch()

  useSubscription(
    authorizationStatus
      ? `/agent/${agentId}/notification/call/load-script`
      : [],
    async (message) => {
      const body: CallScriptResponse = JSON.parse(message.body)

      const id = body.script.campaignId!
      const { data } = await api.findCampaignByIdUsingGet(id)

      // @TODO: check campaign data is defined
      dispatch(
        setCampaign({
          campaignId: data.campaignId!,
          campaignName: data.campaignName!,
        })
      )

      dispatch(setCurrentCampaign(data))
      dispatch(setCurrentScript(body.script))
      dispatch(setExpanded(true))

      // map `customerPhone` to `customer`
      const customer = {
        ...body.customer?.customer,
        customerPhone: body.customer.customerPhone,
      }

      dispatch(setCustomer(customer))

      if (customer.engagementMode === 'Preview') {
        dispatch(setCallState('Preview'))

        const seconds = parseInt(customer.previewTo, 10);

        if (!Number.isNaN(seconds)) {
          dispatch(setPreviewCallTimer(seconds))
        }
      } else {
        dispatch(setCallState('Talking'))
        dispatch(setCallStart())
      }
    }
  )
}
