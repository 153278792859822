import { createAsyncThunk } from '@reduxjs/toolkit'
import { Api } from '../../api/Api'
import { JtapiAgentState } from '../states';

interface ChangeUserStatusType {
  api: Api<unknown>;
  agentId: string;
  stateId: JtapiAgentState[keyof JtapiAgentState]
}

export const changeUserStatus = createAsyncThunk(
  'header/changeUserStatus',
  async ({ api, agentId, stateId, ...params }: ChangeUserStatusType) => {
    await api.changeAgentStateUsingPost(agentId, stateId, params)
  }
)
