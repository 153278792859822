import cn from 'classnames';
import React from 'react'

import './Button.sass'

export interface ButtonProps {
  className?: string
  disabled?: boolean
  text: string
  type?: 'outline'
  onClick: React.MouseEventHandler<HTMLDivElement>
}

export default function Button({
  className,
  disabled,
  text,
  type,
  onClick,
}: ButtonProps) {
  return (
    <div
      className={cn({
        'button': type !== 'outline',
        'button-outline': type === 'outline',
        'button-disabled': disabled
      }, className)}
      role="button"
      tabIndex={disabled ? -1 : 0}
      onClick={!disabled ? onClick : undefined}
    >
      <div className={type === 'outline' ? 'button-outline-text' : 'button-text'}>
        {text}
      </div>
    </div>
  )
}
