import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  AgentJobStatisticsResponse,
  AgentsCompletionCodeStatisticsResponse,
  AgentStatusForSupervisor,
  CampaignResponse,
  CampaignsCompletionCodeStatisticsResponse,
  CompletionCodeEntity,
} from '../../api/data-contracts'
import ViewWithScript from '../../components/ViewWithScript/ViewWithScript'
import { useApi } from '../../store/hooks/request'
import SupervisorDashboardContent from './SupervisorDashboardContent/SupervisorDashboardContent'
import SupervisorDashboardSubheader from './SupervisorDashboardSubheader/SupervisorDashboardSubheader'
export interface AgentsAndCompainsAndCodesType {
  agents: AgentStatusForSupervisor[]
  codes: CompletionCodeEntity[]
  compains: CampaignResponse[]
}
export type selectedAgentsType = Array<
  Pick<AgentStatusForSupervisor, 'agentId' | 'agentName'>
>
export type selectedCompainsType = Pick<
  CampaignResponse,
  'campaignId' | 'campaignName'
>[]
export type selectedCodesType = Pick<
  CompletionCodeEntity,
  'codeId' | 'codeName'
>[]
export type tabStateType = 'stats' | 'ops' | 'comp' | undefined

export default function SupervisorDashboard() {
  const { api } = useApi()
  const [tabType, setTabType] = useState<tabStateType>('stats')

  const [agentsAndCompainsAndCodes, setAgentsAndCompainsAndCodes] =
    useState<AgentsAndCompainsAndCodesType>()

  const [selectedAgents, setSelectedAgents] = useState<selectedAgentsType>([])

  const [selectedCompains, setSelectedCompains] =
    useState<selectedCompainsType>([])

  const [selectedCodes, setSelectedCodes] = useState<selectedCodesType>([])

  const [statisticData, setDashboardData] =
    useState<AgentJobStatisticsResponse>()

  const [opsData, setOpsData] =
    useState<AgentsCompletionCodeStatisticsResponse[]>()

  const [compainsData, setCompainsData] =
    useState<CampaignsCompletionCodeStatisticsResponse[]>()

  const hadleSelectFilters = useCallback(
    (
      selected:
        | Pick<AgentStatusForSupervisor, 'agentId' | 'agentName'>
        | Pick<CampaignResponse, 'campaignId' | 'campaignName'>
        | Pick<CompletionCodeEntity, 'codeId' | 'codeName'>,
      type: 'agent' | 'comp' | 'code'
    ) => {
      switch (type) {
        case 'agent':
          setSelectedAgents((prev) => {
            return [
              ...prev,
              selected as Pick<
                AgentStatusForSupervisor,
                'agentId' | 'agentName'
              >,
            ]
          })
          break
        case 'comp':
          setSelectedCompains((prev) => {
            return [
              ...prev,
              selected as Pick<CampaignResponse, 'campaignId' | 'campaignName'>,
            ]
          })
          break
        case 'code':
          setSelectedCodes((prev) => {
            return [
              ...prev,
              selected as Pick<CompletionCodeEntity, 'codeId' | 'codeName'>,
            ]
          })
          break

        default:
          break
      }
    },

    []
  )

  const handleClear = useCallback(() => {
    setSelectedAgents([])
    setSelectedCompains([])
    setSelectedCodes([])
  }, [])

  const selectedFiltersNames = useMemo(() => {
    return {
      operators: selectedAgents.map((operator) => operator.agentName),
      codes: selectedCodes.map((code) => code.codeName),
      compains: selectedCompains.map((comp) => comp.campaignName),
    }
  }, [selectedAgents, selectedCodes, selectedCompains])

  useEffect(() => {
    const getAgentsAndCompainsAndCodes = async () => {
      const { data: agents } = await api.getAgentsUsingGet()
      const { data: compains } = await api.findAllCampaignsUsingGet()
      const { data: codes } = await api.getAllUsingGet()
      setAgentsAndCompainsAndCodes({ agents, compains, codes })
    }
    getAgentsAndCompainsAndCodes()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const getStatsData = async () => {
      const { data: stats } = await api.getStatisticUsingPost({
        agentIds: selectedAgents.length
          ? selectedAgents.map((agent) => agent.agentId!)
          : undefined,
        campaignIds: selectedCompains.length
          ? selectedCompains.map((comp) => comp.campaignId!)
          : undefined,
      })
      setDashboardData(stats)
    }
    getStatsData()
    // eslint-disable-next-line
  }, [selectedAgents, selectedCompains])
  useEffect(() => {
    const getOpsData = async () => {
      const { data: ops } = await api.getStatisticAgentsCompletionCodeUsingPost(
        {
          agentIds: selectedAgents.length
            ? selectedAgents.map((agent) => agent.agentId!)
            : undefined,
          completionCodeIds: selectedCodes.length
            ? selectedCodes.map((code) => parseInt(code.codeId!))
            : undefined,
        }
      )
      setOpsData(ops)
    }
    getOpsData()
    // eslint-disable-next-line
  }, [selectedAgents, selectedCodes])
  useEffect(() => {
    const getCompData = async () => {
      const { data: comp } =
        await api.getStatisticCampaignsCompletionCodeUsingPost({
          campaignIds: selectedCompains.length
            ? selectedCompains.map((comp) => comp.campaignId!)
            : undefined,
          completionCodeIds: selectedCodes.length
            ? selectedCodes.map((code) => parseInt(code.codeId!))
            : undefined,
        })
      setCompainsData(comp)
    }
    getCompData()
    // eslint-disable-next-line
  }, [selectedCompains, selectedCodes])

  return (
    <ViewWithScript
      ContentComponent={
        <SupervisorDashboardContent
          data={{
            stats: statisticData,
            ops: opsData,
            comp: compainsData,
          }}
          tabSelect={setTabType}
          currentTab={tabType}
          allData={agentsAndCompainsAndCodes}
        />
      }
      SubHeaderComponent={
        <SupervisorDashboardSubheader
          selectedFilters={selectedFiltersNames}
          type={tabType}
          data={agentsAndCompainsAndCodes}
          handleSelectFilters={hadleSelectFilters}
          handleClear={handleClear}
        />
      }
    ></ViewWithScript>
  )
}
