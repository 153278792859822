import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface StompStore {
  connected: boolean
  jtapiAuthorize: boolean
}

const initialState: StompStore = {
  connected: false,
  jtapiAuthorize: false,
}

export const stompSlice = createSlice({
  name: 'stomp',
  initialState,
  reducers: {
    dropStompSlice: () => initialState,
    setStompConnected: (
      state,
      { payload }: PayloadAction<StompStore['connected']>
    ) => {
      state.connected = payload
    },
    setJtapiAuthorizeStatus: (
      state,
      { payload }: PayloadAction<StompStore['jtapiAuthorize']>
    ) => {
      state.jtapiAuthorize = payload
    },
  },
})

export const {
  setJtapiAuthorizeStatus,
  setStompConnected,
  dropStompSlice,
} = stompSlice.actions
export const stompReducer = stompSlice.reducer
