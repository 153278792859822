import React, {
  ChangeEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import Dropdown from '../Elements/Dropdown/Dropdown'
import RectangleButton from '../Elements/Buttons/RectangleButton/RectangleButton'
import ArrowLeft from '../../Icons/Arrows/ArrowLeft'
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hook'
import {
  createCallback,
  getCallbackDests,
  getCallbackTypes,
} from '../../../store/requests/script'
import { userSelector } from '../../../store/selectors/user'
import { useApi } from '../../../store/hooks/request'
import { scriptSelector } from '../../../store/selectors/script'
import {
  chooseCallbackDest,
  chooseCallbackType,
} from '../../../store/slices/scriptSlice'
import DateTimeInput from '../../DateInputs/DateTimeInput/DateTimeInput'
import DateInputProps from '../../DateInputs/types'
import ShortNote from '../Elements/Notes/ShortNote/ShortNote'

interface CallbackProps {
  onClose(): void
  onSuccess?(): void
}

export default function Callback({ onClose, onSuccess }: CallbackProps) {
  const [dateTime, setDateTime] = useState<string | null>(null)
  const [expirationTime, setExpirationTime] = useState<string>('')
  const dispatch = useAppDispatch()
  const { agentId } = useAppSelector(userSelector)
  const {
    callbackDests,
    callbackTypes,
    callState,
    chosenCallbackType,
    chosenCallbackDest,
    chosenContactNumber,
  } = useAppSelector(scriptSelector)
  const { api } = useApi()

  const isInCall = callState === 'Talking'
  const isPreview = callState === 'Callback'
  const isWrapup = callState === 'Wrapup'
  const isUnable = !(isInCall || isPreview || isWrapup) // нельзя в других стейтах сделать коллбек

  useEffect(() => {
    if (agentId) {
      dispatch(getCallbackTypes({ api, agentId }))
    }
    // eslint-disable-next-line
  }, [agentId, dispatch])

  useEffect(() => {
    if (!dateTime) setDateTime(new Date().toISOString())
  }, [dateTime])

  const callbackTypesOptions = useMemo(() => {
    return callbackTypes.map((str) => ({ text: str, value: str, key: str }))
  }, [callbackTypes])

  const callbackDestsOptions = useMemo(
    () =>
      callbackDests?.callbackDests?.map((el) => ({
        text: `${el.destinationName!} ${el.destinationValue!}`,
        value: el.destinationValue!,
        key: el.destinationValue!,
      })),
    [callbackDests]
  )

  const handleChooseCallbackType: ChangeEventHandler<HTMLSelectElement> =
    useCallback(
      (e) => {
        dispatch(chooseCallbackType(e.target.value))
        if (e.target.value === 'Не выбрано') return
        dispatch(getCallbackDests({ api, type: e.target.value }))
      },
      // eslint-disable-next-line
      [dispatch]
    )

  const handleChooseCallbackDest: ChangeEventHandler<HTMLSelectElement> =
    useCallback(
      (e) => {
        const callbackDest = callbackDests?.callbackDests?.find(
          (el) => el.destinationValue === e.target.value
        )
        if (callbackDest) {
          dispatch(chooseCallbackDest(callbackDest))
        } else {
          dispatch(chooseCallbackDest(null))
        }
      },
      [dispatch, callbackDests]
    )

  const handleCreateCallback = useCallback(() => {
    if (dateTime && chosenCallbackDest && chosenContactNumber) {
      dispatch(
        createCallback({
          api,
          agentId,
          callbackDest: chosenCallbackDest,
          contactNumber: chosenContactNumber,
          callbackTime: dateTime,
          callbackType: chosenCallbackDest.callbackType,
          callbackExpiryTime: expirationTime,
        })
      )

      if (onSuccess) {
        onSuccess()
      } else {
        onClose()
      }
    }
    // eslint-disable-next-line
  }, [agentId, chosenCallbackDest, chosenContactNumber, onClose, dispatch])

  const handleChangeDate: DateInputProps['onChange'] = useCallback((date) => {
    const isoString = date?.toISOString()
    if (isoString) {
      setDateTime(isoString)
    }
  }, [])

  const handleChangeExpirationTime: ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    const { value } = e.target

    if (value === '') {
      return setExpirationTime('')
    }

    const match = value.match(/\d*/)

    if (match && match[0] === value) {
      const parsed = parseFloat(value)

      if (parsed < 100) {
        setExpirationTime(String(parsed))
      }
    }
  }

  return (
    <div className="step">
      <div className="step-header">
        <div className="step-header-arrow" onClick={onClose}>
          <ArrowLeft />
        </div>

        <div className="step-title-name">Обратный вызов</div>
      </div>

      {!isUnable && (
        <>
          <div className="step-phone-choose-header">Дата и время</div>

          <DateTimeInput className="step-element" onChange={handleChangeDate} />

          <div className="step-phone-choose">
            <div className="step-phone-choose-header">
              Истечение срока обратного звонка
            </div>

            <ShortNote
              onChange={handleChangeExpirationTime}
              value={expirationTime}
              placeholder="В минутах"
            />
          </div>

          {!!callbackTypesOptions.length && (
            <div className="step-phone-choose">
              <div className="step-phone-choose-header">
                Тип обратного звонка
              </div>

              <Dropdown
                withEmptyValue
                options={callbackTypesOptions}
                value={chosenCallbackType || ''}
                onChange={handleChooseCallbackType}
              />
            </div>
          )}

          {!!callbackDestsOptions?.length && (
            <div className="step-phone-choose">
              <div className="step-phone-choose-header">
                Кандидаты для обратного звонка
              </div>

              <Dropdown
                withEmptyValue
                options={callbackDestsOptions}
                value={chosenCallbackDest?.destinationValue || ''}
                onChange={handleChooseCallbackDest}
              />
            </div>
          )}

          {!!chosenCallbackDest && (
            <RectangleButton
              className="step-phone-choose"
              label="Назначить callback"
              onClick={handleCreateCallback}
              isBlue={callState !== 'Talking'}
            />
          )}
        </>
      )}
    </div>
  )
}
