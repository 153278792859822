import { memo, useCallback, useEffect } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { StompSessionProvider } from 'react-stomp-hooks'
import { useAppDispatch } from '../../store/hooks/hook'
import { setStompConnected } from '../../store/slices/stompSlice'
import Main from '../../views/Main/Main'

interface PrivateRouterProps {
  isAuth: string | null | undefined
}

function PrivateRoute({ isAuth }: PrivateRouterProps) {
  const token = isAuth?.replace('BASIC', ' ').trim()
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(setStompConnected(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const connectStompHandler = useCallback(() => {
    dispatch(setStompConnected(true))
    // eslint-disable-next-line
  }, [dispatch])

  const disconnectStompHandler = useCallback(
    () => dispatch(setStompConnected(false)),
    [dispatch]
  )

  return isAuth ? (
    <StompSessionProvider
      debug={(msg) => console.log(msg)}
      url={`/core/notifications?token=${token}`}
      onConnect={connectStompHandler}
      onDisconnect={disconnectStompHandler}
    >
      <Main>
        <Outlet />
      </Main>
    </StompSessionProvider>
  ) : (
    <Navigate to="/login" replace />
  )
}

export default memo(PrivateRoute)
