import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import moment from 'moment'

import {
  ScriptResponse,
  WrapupPomUniversalResponse,
  CallbackDestPom,
  ContactNumber,
  CallbackDestsPomResponse,
} from '../../api/data-contracts'
import { CallScriptCustomer } from '../../types';
import { cancelDial, previewDial, releaseLine } from '../requests/script'

export interface ScriptStore {
  isExpanded: boolean
  currentScript: ScriptResponse | null
  currentScriptHistory: number[]
  customer: CallScriptCustomer | null
  callStart: number | null
  completionCodeChoose: boolean
  releaseLineAnswer?: WrapupPomUniversalResponse | null
  callState:
    | 'NoState'
    | 'Preview'
    | 'Idle'
    | 'Dialing'
    | 'Talking'
    | 'Held'
    | 'Wrapup'
    | 'Consult'
    | 'ConferenceOwner'
    | 'ConferencePassive'
    | 'Callback'
  callbackTypes: string[]
  callbackDests: CallbackDestsPomResponse | null
  previewCallTimer?: number | null
  chosenContactNumber: ContactNumber | null
  chosenCallbackType: string | null
  chosenCallbackDest: CallbackDestPom | null
  callbackWindowOpen: boolean
}

const initialState: ScriptStore = {
  isExpanded: false,
  currentScript: null,
  currentScriptHistory: [],
  customer: null,
  callStart: null,
  completionCodeChoose: false,
  callState: 'NoState',
  callbackTypes: [],
  callbackDests: null,
  previewCallTimer: null,
  chosenContactNumber: null,
  chosenCallbackType: null,
  chosenCallbackDest: null,
  callbackWindowOpen: false,
}

export const scriptSlice = createSlice({
  name: 'script',
  initialState,
  reducers: {
    dropScriptSlice: () => initialState,
    setExpanded(
      state: ScriptStore,
      { payload }: PayloadAction<ScriptStore['isExpanded']>
    ) {
      state.isExpanded = payload
    },
    setScriptHistoryPush(
      state: ScriptStore,
      { payload }: PayloadAction<number>
    ) {
      state.currentScriptHistory.push(payload)
    },
    setScriptHistoryPop(state: ScriptStore) {
      state.currentScriptHistory.pop()
    },
    setCallStart(state) {
      state.callStart = moment().unix()
    },
    setPreviewCallTimer(state, { payload }: PayloadAction<number>) {
      state.previewCallTimer = moment().add(payload, 'seconds').unix()
    },
    setReleaseLineAnswer(
      state,
      { payload }: PayloadAction<ScriptStore['releaseLineAnswer']>
    ) {
      if (payload) {
        state.releaseLineAnswer = payload
      }
    },
    setCallToEnd(state) {
      state.callState = 'Wrapup'
      state.completionCodeChoose = true
    },
    setCallState(state, { payload }: PayloadAction<ScriptStore['callState']>) {
      state.callState = payload
    },
    setCallbackTypes(
      state,
      { payload }: PayloadAction<ScriptStore['callbackTypes']>
    ) {
      state.callbackTypes = payload
    },
    setCallbackDests(
      state,
      { payload }: PayloadAction<ScriptStore['callbackDests']>
    ) {
      state.callbackDests = payload
    },
    chooseCallbackType(
      state,
      { payload }: PayloadAction<ScriptStore['chosenCallbackType']>
    ) {
      state.chosenCallbackType = payload
    },
    chooseCallbackDest(
      state,
      { payload }: PayloadAction<ScriptStore['chosenCallbackDest']>
    ) {
      state.chosenCallbackDest = payload
    },
    chooseContactNumber(
      state,
      { payload }: PayloadAction<ScriptStore['chosenContactNumber']>
    ) {
      state.chosenContactNumber = payload
    },
    setCallbackWindowOpen(
      state,
      { payload }: PayloadAction<ScriptStore['callbackWindowOpen']>
    ) {
      state.callbackWindowOpen = payload
    },
    setCurrentScript(
      state,
      { payload }: PayloadAction<ScriptStore['currentScript']>
    ) {
      state.currentScript = payload
    },
    setCustomer(
      state,
      { payload }: PayloadAction<ScriptStore['customer']>
    ) {
      if (!payload) {
        state.customer = payload
        return;
      }

      // map `customDataX` fields to single `customData` object
      const customData = Object.keys(payload).reduce((data, key) => {
        if (!key.startsWith('customData')) {
          return data;
        }

        const item = payload[key];

        if (item.label) {
          data[item.label] = item.value
        }

        return data;
      }, {});

      state.customer = {
        ...payload,
        customData
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(releaseLine.fulfilled, (state) => {
      state.currentScript = null
      state.currentScriptHistory = []
      state.customer = null
      state.callStart = null
      state.completionCodeChoose = false
      state.callState = 'NoState'
      state.currentScriptHistory = []
      state.releaseLineAnswer = null
      state.previewCallTimer = null
      state.chosenContactNumber = null
      state.callbackWindowOpen = false
    })
    builder.addCase(previewDial.fulfilled, (state) => {
      state.callState = 'Talking'
      state.callStart = moment().unix()
      state.previewCallTimer = null
    })
    builder.addCase(cancelDial.fulfilled, (state) => {
      state.completionCodeChoose = true
    })
  },
})

export const {
  setExpanded,
  setScriptHistoryPush,
  setScriptHistoryPop,
  setCallToEnd,
  setReleaseLineAnswer,
  setCallState,
  setCallbackTypes,
  setCallbackDests,
  chooseCallbackType,
  chooseCallbackDest,
  chooseContactNumber,
  setCallbackWindowOpen,
  dropScriptSlice,
  setCallStart,
  setCustomer,
  setCurrentScript,
  setPreviewCallTimer
} = scriptSlice.actions

export const scriptReducer = scriptSlice.reducer
