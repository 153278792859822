import React, { MouseEventHandler } from 'react'

import ButtonArrow from '../../../../Icons/ButtonArrow/ButtonArrow'
import { useAppSelector } from '../../../../../store/hooks/hook'
import { scriptSelector } from '../../../../../store/selectors/script'

import './RadioButton.sass'

interface RadioButtonProps {
  label?: string
  isActive?: boolean | undefined
  onClick?: MouseEventHandler<HTMLDivElement>
  className?: string
}

export default function RadioButton({
  label,
  // isActive,
  onClick,
  className,
}: RadioButtonProps) {
  const { callState } = useAppSelector(scriptSelector)

  return (
    <div
      className={
        className ? className + ' radio-button-wrapper' : 'radio-button-wrapper'
      }
      onClick={onClick}
    >
      <span className={callState === 'Talking' ? 'red-text' : ''}>{label}</span>

      <div>
        <ButtonArrow color={callState === 'Talking' ? '#AD3928' : '#397FBF'} />
      </div>
    </div>
  )
}
