import { useEffect, useState } from 'react'
import { Layout, Layouts, Responsive, WidthProvider } from 'react-grid-layout'

import { useAppSelector } from '../../../store/hooks/hook'
import { useApi } from '../../../store/hooks/request'
import { dashboardSelector } from '../../../store/selectors/dashboard'
import { userSelector } from '../../../store/selectors/user'

import InfoItem from '../../../components/InfoItem/InfoItem'
import { TopDashboardItems } from '../../Settings/SettingsContent/TopDashEditor/types'

import './ShiftSummary.sass'

const ResponsiveGridLayout = WidthProvider(Responsive)

export default function ShiftSummary() {
  const { statistic } = useAppSelector(dashboardSelector)
  const { agentGroupId } = useAppSelector(userSelector)
  const { api } = useApi()
  const [layout, setLayout] = useState<Layouts>()

  useEffect(() => {
    const getAgentLayout = async () => {
      const { data } = await api.getDashboardWidgetStateUsingGet(agentGroupId!)
      setLayout({ lg: data.widgetStateList as unknown as Layout[] })
    }

    getAgentLayout()
    // eslint-disable-next-line
  }, [agentGroupId])

  return (
    <div className="shift-summary-wrapper">
      <ResponsiveGridLayout
        layouts={layout}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 20, md: 10, sm: 8, xs: 6, xxs: 4 }}
        rowHeight={40}
        isDraggable={false}
        isResizable={false}
      >
        {layout?.lg?.map((dash) => {
          switch (dash.i) {
            case TopDashboardItems.Calls:
              return (
                <div className="widget" key={TopDashboardItems.Calls}>
                  <InfoItem
                    data={statistic?.callCount || '--'}
                    title="Всего вызовов"
                  />
                </div>
              )
            case TopDashboardItems.AverageCallTime:
              return (
                <div className="widget" key={TopDashboardItems.AverageCallTime}>
                  <InfoItem
                    data={statistic?.avgTalkTime || '--:--'}
                    title="Среднее время вызова"
                  />
                </div>
              )
            case TopDashboardItems.StartShift:
              return (
                <div className="widget" key={TopDashboardItems.StartShift}>
                  <InfoItem
                    data={statistic?.loginTimeStamp || '--:--'}
                    title="Начало смены"
                    active
                  />
                </div>
              )
            case TopDashboardItems.WorkTime:
              return (
                <div className="widget" key={TopDashboardItems.WorkTime}>
                  <InfoItem
                    data={statistic?.totalJobTime || '--:--'}
                    title="Время работы"
                  />
                </div>
              )
            case TopDashboardItems.RPC:
              return (
                <div className="widget" key={TopDashboardItems.RPC}>
                  <InfoItem data={statistic?.rpcCount || '--'} title="RPC" />
                </div>
              )
            case TopDashboardItems.Deals:
              return (
                <div className="widget" key={TopDashboardItems.Deals}>
                  <InfoItem
                    data={statistic?.closureCount || '--'}
                    title="Сделок"
                  />
                </div>
              )
            case TopDashboardItems.Sucess:
              return (
                <div className="widget" key={TopDashboardItems.Sucess}>
                  <InfoItem
                    data={statistic?.successCalls || '--'}
                    title="Успешных"
                  />
                </div>
              )
            default:
              return <></>
          }
        })}
      </ResponsiveGridLayout>
    </div>
  )
}
