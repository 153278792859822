import { useSubscription } from 'react-stomp-hooks'

import { onJobAttachedGetScript } from '../../store/requests/script'
import { useAppDispatch, useAppSelector } from '../../store/hooks/hook'
import { userSelector } from '../../store/selectors/user'
import {
  setCallToEnd,
  setReleaseLineAnswer,
} from '../../store/slices/scriptSlice'
import { Api } from '../../api/Api'

export default function useJobAttached(
  authorizationStatus: boolean,
  api: Api<unknown>
) {
  const { agentId } = useAppSelector(userSelector)

  const dispatch = useAppDispatch()

  useSubscription(
    authorizationStatus ? `/agent/${agentId}/jobAttached/notify` : [],
    (mes) => {
      const campaignName = JSON.parse(mes.body)?.campaignName

      if (campaignName) {
        dispatch(onJobAttachedGetScript({ campaignName, api }))
      }
    }
  )

  useSubscription(
    authorizationStatus ? `/agent/${agentId}/release-line` : [],
    (mes) => {
      const body = JSON.parse(mes.body)

      dispatch(setReleaseLineAnswer(body))

      if (body?.message === 'FAILURE') {
        dispatch(setCallToEnd())
      }
    }
  )
}
