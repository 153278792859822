import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  authorize,
  getInactivityLogoutSettings,
  getUserData,
  getAgents,
  getSupervisorData,
  getAgentCallStatusUsing,
} from '../requests/user'
import {
  AgentLoginResponse,
  AgentProfileEntity,
  InactivityLogoutSettingsResponse,
  AgentStatusForSupervisor,
  AgentLoginRequest,
  SupervisorInfoResponse,
  AgentCallStatus,
} from '../../api/data-contracts'
import { IncomingHttpHeaders } from 'http'
import {
  notificationIncorrect,
  Notification,
  NotificationList,
} from './notificationSlice'
import moment from 'moment'

export interface AgentProcessing {
  id: string
  name: string
}

export interface UserStore
  extends AgentLoginResponse,
    AgentProfileEntity,
    SupervisorInfoResponse {
  auth?: IncomingHttpHeaders['authorization'] | null
  loginNotifications: NotificationList
  inactivitySettings: InactivityLogoutSettingsResponse | null
  agentsForSuper?: AgentStatusForSupervisor[]
  listeningAgent: {
    callStart: number | null
    name: string
  }
  wrongLoginCount: number
  pendingAuthLoginName: string
  autofillData: AgentLoginRequest
  isLogouting: boolean
  callAgentModalOpen: boolean
  logoutAgentModalOpen: boolean
  agentStatus: boolean
  agentProcessing: AgentProcessing | null
}

const initialState: UserStore = {
  loginNotifications: [],
  inactivitySettings: null,
  listeningAgent: {
    callStart: null,
    name: '',
  },
  wrongLoginCount: 0,
  pendingAuthLoginName: '',
  autofillData: {},
  isLogouting: false,
  agentStatus: false,
  callAgentModalOpen: false,
  logoutAgentModalOpen: false,
  agentProcessing: null,
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    dropUserSlice: () => initialState,
    addLoginNotification(
      state: UserStore,
      { payload }: PayloadAction<Notification>
    ) {
      state.loginNotifications.push(payload)
    },
    delLoginNotification(
      state: UserStore,
      { payload }: PayloadAction<Notification['id']>
    ) {
      state.loginNotifications = state.loginNotifications?.filter(
        (notification) => notification.id !== payload
      )
    },
    deleteAuthToken(state: UserStore) {
      state.auth = null
    },
    setListeningAgent(state: UserStore, { payload }: PayloadAction<string>) {
      state.listeningAgent.name = payload
      state.listeningAgent.callStart = moment().unix()
    },
    stopListeningAgent(state: UserStore) {
      state.listeningAgent = initialState.listeningAgent
    },
    setPendingAuthLoginName(
      state: UserStore,
      { payload }: PayloadAction<UserStore['pendingAuthLoginName']>
    ) {
      state.pendingAuthLoginName = payload
    },
    setAutoFillData(
      state: UserStore,
      { payload }: PayloadAction<UserStore['autofillData']>
    ) {
      state.autofillData = payload
    },
    setLogoutingStatus(
      state,
      { payload }: PayloadAction<UserStore['isLogouting']>
    ) {
      state.isLogouting = payload
    },
    setCallAgentModalOpen(
      state,
      { payload }: PayloadAction<UserStore['callAgentModalOpen']>
    ) {
      state.callAgentModalOpen = payload
    },
    setLogoutAgentModalOpen(
      state,
      { payload }: PayloadAction<UserStore['logoutAgentModalOpen']>
    ) {
      state.logoutAgentModalOpen = payload
    },
    setAgentProcessing(
      state,
      { payload }: PayloadAction<UserStore['agentProcessing']>
    ) {
      state.agentProcessing = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getInactivityLogoutSettings.fulfilled,
      (
        state: UserStore,
        { payload }: PayloadAction<InactivityLogoutSettingsResponse>
      ) => {
        state.inactivitySettings = payload
      }
    )
    builder.addCase(
      getAgentCallStatusUsing.fulfilled,
      (state: UserStore, { payload }: PayloadAction<AgentCallStatus>) => {
        state.agentStatus = Boolean(payload.isAgentInCall)
      }
    )
    builder.addCase(
      authorize.fulfilled,
      (state: UserStore, { payload }: PayloadAction<UserStore>) => {
        Object.assign(state, payload)
        state.wrongLoginCount = 0
        state.pendingAuthLoginName = ''
      }
    )
    builder.addCase(authorize.rejected, (state: UserStore) => {
      state.wrongLoginCount++
      state.loginNotifications.push({
        ...notificationIncorrect,
        id: Date.now(),
      })
    })
    builder.addCase(
      getUserData.fulfilled,
      (state: UserStore, { payload }: PayloadAction<AgentProfileEntity>) => {
        delete payload.agentId
        Object.assign(state, payload)
      }
    )
    builder.addCase(
      getAgents.fulfilled,
      (
        state: UserStore,
        { payload }: PayloadAction<AgentStatusForSupervisor[]>
      ) => {
        state.agentsForSuper = payload
      }
    )
    builder.addCase(getSupervisorData.fulfilled, (state, { payload }) => ({
      ...state,
      ...payload,
    }))
  },
})

export const {
  dropUserSlice,
  addLoginNotification,
  delLoginNotification,
  deleteAuthToken,
  setListeningAgent,
  stopListeningAgent,
  setPendingAuthLoginName,
  setAutoFillData,
  setLogoutingStatus,
  setCallAgentModalOpen,
  setLogoutAgentModalOpen,
  setAgentProcessing,
} = userSlice.actions
export const userReducer = userSlice.reducer
