import { memo } from 'react'
import Group from '../../../../../components/Icons/SupervisorChatDashboardInfo/Group'
import Group2 from '../../../../../components/Icons/SupervisorChatDashboardInfo/Group2'
import { GroupChatStoreItem } from '../../../../../store/slices/chatSlice'
import './GroupChatInfo.sass'
import { SelectedChat } from '../../../../../store/slices/chatSlice'

interface GroupChatInfoProps extends GroupChatStoreItem {
  type: SelectedChat['type']
}
function GroupChatInfo({ chatName, members }: GroupChatInfoProps) {
  return (
    <div className="supervisor-group-chats-info">
      <div className="supervisor-group-chats-info-header">
        <div className="header-info">Информация о группе</div>
        <div className="supervisor-group-chats-info-header-content">
          <Group />
          <div className="header-content-info">
            <div className="group-info">
              <div className="group-name">{chatName}</div>
              <div className="group-id">Group</div>
            </div>

            <div className="group-members-count">
              {members?.length} участников
            </div>
          </div>
        </div>
      </div>
      <div className="supervisor-group-chats-info-content">
        <div className="supervisor-group-chats-info-content-header">
          <Group2 />{' '}
          <span className="members-count">{members?.length} участников</span>
        </div>
        {members?.map((member, id) => {
          return (
            <div className="group-member" key={id}>
              <div className="member-initials">{member.name![0]}</div>
              <div className="member-info">
                <span className="member-name">{member.name}</span>
                <span className="member-role">
                  {(member.role === 'ROLE_SUPERVISOR' && 'Супервизор') ||
                    (member.role === 'ROLE_USER' && 'Агент')}
                </span>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
export default memo(GroupChatInfo)
