import React from 'react'

interface EndCallProps {
  size?: number
}

export default function EndCall({ size = 32 }: EndCallProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5595 15.602C12.5968 17.7227 14.3158 19.4339 16.4412 20.4615C16.5967 20.5352 16.7687 20.5671 16.9403 20.5541C17.1119 20.5412 17.2771 20.4837 17.4198 20.3875L20.5492 18.3007C20.6877 18.2084 20.8469 18.1521 21.0126 18.1369C21.1782 18.1217 21.3451 18.148 21.498 18.2135L27.3526 20.7227C27.5515 20.8071 27.7175 20.954 27.8257 21.141C27.9339 21.3281 27.9783 21.5452 27.9524 21.7597C27.7673 23.2078 27.0608 24.5387 25.9652 25.5033C24.8695 26.4679 23.4598 27 22 27.0001C17.4913 27.0001 13.1673 25.2091 9.97919 22.0209C6.79107 18.8328 5 14.5088 5 10.0001C5.00008 8.54034 5.53224 7.13064 6.49685 6.03497C7.46146 4.9393 8.79237 4.2328 10.2404 4.04775C10.4549 4.02179 10.672 4.06625 10.8591 4.17443C11.0461 4.2826 11.193 4.44864 11.2775 4.64753L13.7888 10.5073C13.8537 10.6588 13.8802 10.8241 13.8658 10.9884C13.8514 11.1526 13.7967 11.3108 13.7064 11.4488L11.6268 14.6263C11.5322 14.7692 11.4762 14.9342 11.4644 15.1053C11.4526 15.2763 11.4854 15.4475 11.5595 15.602V15.602Z"
        stroke="#AD3928"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 6L26 12"
        stroke="#AD3928"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26 6L20 12"
        stroke="#AD3928"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
