import Avatar from '../../../components/Avatar/Avatar'
import InfoIcon from '../../../components/Icons/Chat/InfoIcon/InfoIcon'
import InfoItem from '../../../components/InfoItem/InfoItem'
import { useAppSelector } from '../../../store/hooks/hook'
import { headerSelector } from '../../../store/selectors/header'
import { userSelector } from '../../../store/selectors/user'
import './DashboardUserInfo.sass'
import { JTAPI_AGENT_STATES } from '../../../store/states';

export default function DashboardUserInfo() {
  const { agentName } = useAppSelector(userSelector)
  const { agentStateNotify } = useAppSelector(headerSelector)

  return (
    <div className="dashboard-userinfo-wrapper">
      <div className="dashboard-userinfo-info">
        <Avatar hasStatus={false} forMessages />
        <InfoItem
          data={agentName!}
          title={
            agentStateNotify !== JTAPI_AGENT_STATES.NOT_READY && agentStateNotify !== null
              ? 'Готов'
              : 'Не готов'
          }
        />
      </div>
      <div className="dashboard-userinfo-icons">
        <InfoIcon />
      </div>
    </div>
  )
}
