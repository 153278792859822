import React, {ReactNode} from 'react'
import Header from '../../components/Header/Header'
import Sidebar from '../../components/Sidebar/Sidebar'
import NotFound from '../NotFound/NotFound'
import Ghost from '../../components/Icons/Ghost/Ghost'
import useJtapiRegistrationStatus from '../../hooks/user/useJtapiRegistrationStatus'
import useJobAttached from '../../hooks/script/useJobAttached'
import ContentLayout from '../../components/ContentLayout/ContentLayout'
import useStateChangeNotify from '../../hooks/user/useStateChangeNotify'
import './Main.sass'
import useJobStatisticSubscriptions from '../../hooks/user/useJobStatisticSubscriptions'
import useChatSubscriptions from '../../hooks/user/useChatSubscriptions'
import useAgentRequests from '../../hooks/notifications/useAgentRequests'
import useGetNotifications from '../../hooks/notifications/useGetNotifications'
import useLogout from '../../hooks/user/useLogout'
import useInactivity from '../../hooks/user/useInactivity'
import useCallStateChange from '../../hooks/script/useCallStateChange'
import useScriptCallback from '../../hooks/script/useScriptCallback'
import {useApi} from '../../store/hooks/request'

interface MainProps {
  isAllowed?: boolean
  children: ReactNode
}

export default function Main({ children, isAllowed = true }: MainProps) {
  const { jtapiAuthorize } = useJtapiRegistrationStatus()
  const { api, logoffApi } = useApi()

  useStateChangeNotify(jtapiAuthorize)
  useJobAttached(jtapiAuthorize, api)
  useJobStatisticSubscriptions(jtapiAuthorize)
  useChatSubscriptions()
  useAgentRequests(jtapiAuthorize, api)
  useGetNotifications(jtapiAuthorize, api)
  useLogout({ jtapiAuthorize, api, logoffApi })
  useInactivity(jtapiAuthorize, api)
  useCallStateChange(jtapiAuthorize)
  useScriptCallback(jtapiAuthorize)

  if (!isAllowed) return <NotFound />

  return (
    <div className="main-layout">
      <Header className="header-layout" />
      <div className="content-layout">
        <Sidebar className="content-layout-sider" />
        <ContentLayout>
          {jtapiAuthorize ? children : <Ghost load />}
        </ContentLayout>
      </div>
    </div>
  )
}
