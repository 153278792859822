import React from 'react'

import SearchIcon from '../../../../components/Icons/Chat/SearchIcon/SearchIcon'

import './Header.sass'

export default function Header() {
  return (
    <div className="super-call-header">
      <div className="super-call-header-left">
        {/*  TODO Добавить метки/фильтры для поиска  */}
      </div>

      <div className="super-call-header-right">
        <div className="super-call-header-right-icon">
          <SearchIcon />
        </div>
      </div>
    </div>
  )
}
