import cn from 'classnames'
import React, { MouseEventHandler } from 'react'

import ButtonArrow from '../../../../Icons/ButtonArrow/ButtonArrow'
import ButtonClose from '../../../../Icons/ButtonClose/ButtonClose'

import './RectangleButton.sass'

interface RectangleButtonProps {
  label: string
  onClick: MouseEventHandler<HTMLButtonElement>
  className?: string
  isBlue?: boolean
  negative?: boolean
}

export default function RectangleButton({
  label,
  onClick,
  className,
  isBlue,
  negative,
}: RectangleButtonProps) {
  const icon = negative ? (
    <ButtonClose color={isBlue ? '#397FBF' : ''} />
  ) : (
    <ButtonArrow color={isBlue ? '#397FBF' : ''} />
  )

  return (
    <button className={cn(className, 'rectangle-button')} onClick={onClick}>
      <span className={isBlue ? 'preview-span' : ''}>{label}</span>
      <div>{icon}</div>
    </button>
  )
}
