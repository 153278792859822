import { useSubscription } from 'react-stomp-hooks'

import { DefaultAgentMessageResponse } from '../../api/data-contracts'
import { Api } from '../../api/Api'
import { Logoff } from '../../api/Logoff'
import { useAppDispatch, useAppSelector } from '../../store/hooks/hook'
import { userSelector } from '../../store/selectors/user'
import { logoff } from '../../store/requests/user'
import { setJtapiAuthorizeStatus } from '../../store/slices/stompSlice'
import { changeState } from '../../store/requests/header'
import { setLogoutingStatus } from '../../store/slices/userSlice'
import { JTAPI_AGENT_STATES } from '../../store/states';

interface UseLogoutProps {
  jtapiAuthorize: boolean
  api: Api<unknown>
  logoffApi: Logoff<unknown>
}

export default function useLogout({
  jtapiAuthorize,
  api,
  logoffApi,
}: UseLogoutProps) {
  const { agentId, role } = useAppSelector(userSelector)
  const dispatch = useAppDispatch()

  useSubscription(
    jtapiAuthorize ? `/agent/${agentId}/deregistration/jtapi` : [],
    (mes) => {
      const body: DefaultAgentMessageResponse = JSON.parse(mes.body)
      if (body.message === 'SUCCESS' || body.message === null) {
        dispatch(setJtapiAuthorizeStatus(false))
        dispatch(logoff({ logoffApi }))
      }
    }
  )

  useSubscription(
    role?.[0] === 'ROLE_USER'
      ? `/agent/${agentId}/notification/emergencyLogout`
      : [],
    async (mes) => {
      const body: DefaultAgentMessageResponse = JSON.parse(mes.body)

      if (body.message === 'JTAPI_EMERGENCY_LOGOUT') {
        await dispatch(
          changeState({
            api,
            agentId: agentId ?? '',
            stateId: JTAPI_AGENT_STATES.NOT_READY

            // @TODO: delete?
            // reasonCode: '500',
            // reasonName: 'EMERGENCY_LOGOUT',
          })
        )
        dispatch(setLogoutingStatus(true))
      }
    }
  )
}
