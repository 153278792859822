import './Message.sass'
import { memo } from 'react'
import Avatar from '../../../components/Avatar/Avatar'
import { SelectedChat } from '../../../store/slices/chatSlice'
import { useAppSelector } from '../../../store/hooks/hook'
import { chatSelector } from '../../../store/selectors/chat'
import { getTimeFromLastMessage } from '../InnerChatArea/ChatList/utils'

interface MessageProps {
  id: string
  text: string
  time: string
  from: boolean
  haveAvatar: boolean
  fromName?: string
  type: SelectedChat['type']
  handleClick?: () => void
}

function Message(props: MessageProps) {
  const { searchString } = useAppSelector(chatSelector)
  const time = new Date(props.time)
  const mesTime = getTimeFromLastMessage(time)

  return (
    <div
      className={`message-with-avatar-${props.from ? 'sender' : 'income'}${
        searchString ? ' message-with-avatar-clickable' : ''
      }`}
      id={props.id}
      onClick={props.handleClick ? props.handleClick : () => {}}
    >
      {props.type === 'group' && !props.from && (
        <div className="message-sender-name">{props.fromName}:</div>
      )}
      <div className="message-container">
        <span className="message-container-text">{props.text}</span>
        <span className="message-container-time">{mesTime}</span>
      </div>
      {props.from && <Avatar hasStatus={true} forMessages={true} />}
    </div>
  )
}
export default memo(Message)
