import React, { ChangeEventHandler, useState, useEffect, useMemo } from 'react'

import { useAppDispatch, useAppSelector } from '../../../store/hooks/hook'
import { useApi } from '../../../store/hooks/request'
import { campaignSelector } from '../../../store/selectors/campaign'
import { scriptSelector } from '../../../store/selectors/script'
import { releaseLine } from '../../../store/requests/script'

import Dropdown from '../Elements/Dropdown/Dropdown'
import RectangleButton from '../Elements/Buttons/RectangleButton/RectangleButton'

export default function CompletionCodes() {
  const [code, setCode] = useState<number | string | null>(null)

  const { api } = useApi()
  const dispatch = useAppDispatch()

  const { customer, currentScript } = useAppSelector(scriptSelector)
  const { campaignInfo } = useAppSelector(campaignSelector)

  const completionCodes = useMemo(
    () =>
      currentScript?.steps
        ?.filter(
          (step) => !!step.completionCode?.id && !!step.completionCode?.name
        )
        ?.map((step) => ({
          text: step.completionCode?.name || '',
          value: step.completionCode?.id || '',
          key: step.completionCode?.id || '',
        })),
    [currentScript]
  )

  useEffect(() => {
    if (!code && !!completionCodes?.length) {
      setCode(completionCodes[0].value)
    }
  }, [code, completionCodes])

  const handleChooseCode: ChangeEventHandler<HTMLSelectElement> | undefined = (
    e
  ) => {
    const codeValue = Number(e.target.value)
    const compObj = completionCodes?.find(({ value }) => value === codeValue)

    setCode(compObj?.value || null)
  }

  const handleGoToNextCall = () => {
    const codeObj = completionCodes?.find(({ value }) => value === code)

    if (codeObj) {
      dispatch(
        releaseLine({
          api,
          campaignId: String(campaignInfo?.campaignId),
          customerPhone: customer?.customerPhone!,
          reasonName: codeObj?.text,
          reasonCode: String(codeObj.value),
        })
      )
    }
  }

  // @TODO: currently not supported
  // const handleOpenCallbackWindow = useCallback(() => {
  //   dispatch(setCallbackWindowOpen(true))
  // }, [dispatch])

  return (
    <div className="step">
      <div className="step-header">
        <div className="step-title-name">Завершение вызова</div>
      </div>

      <div className="step-phone-choose">
        <div className="step-phone-choose-header">Код завершения:</div>

        <Dropdown
          options={completionCodes}
          value={code || ''}
          onChange={handleChooseCode}
        />
      </div>

      <div className="step-bottom-buttons">
        <RectangleButton
          isBlue
          label="Следующий вызов"
          onClick={handleGoToNextCall}
        />

        {/*
        // @TODO: currently not supported
        <RectangleButton
          className="step-bottom-buttons-lower"
          isBlue
          label="Запланировать callback"
          onClick={handleOpenCallbackWindow}
        />
        */}
      </div>
    </div>
  )
}
