import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Tag } from '../../api/data-contracts'
import { getAllTags } from '../requests/tag'
import { colors, ColorType } from '../colors'

export interface TagType extends Tag {
  id?: number
  tagColor?: ColorType
  text?: string
}

export type TagStore = {
  tagBar: TagType[]
  allTags: TagType[]
}

const initialState: TagStore = {
  tagBar: [],
  allTags: [],
}

export const tagSlice = createSlice({
  name: 'tag',
  initialState,
  reducers: {
    dropTagSlice: () => initialState,
    setTags(state: TagStore, { payload }: PayloadAction<Tag[]>) {
      state.tagBar = payload.map((el) => ({
        ...el,
        tagColor: colors.find((color) => color.background === el.color),
      }))
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getAllTags.fulfilled,
      (state, { payload }: PayloadAction<TagType[]>) => {
        state.allTags = payload.map((el) => ({
          ...el,
          tagColor: colors.find((color) => color.background === el.color),
        }))
      }
    )
  },
})

export const { setTags, dropTagSlice } = tagSlice.actions
export const tagReducer = tagSlice.reducer
