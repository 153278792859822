import React, { MouseEventHandler, ChangeEventHandler } from 'react'

import Button from '../Button/Button'
import InnerModalRadio from './InnerModal/InnerModalRadio/InnerModalRadio'

import './Modal.sass'

type ModalStyles = {
  left?: string
  right?: string
  top?: string
  bottom?: string
}

interface ModalProps {
  title: string
  body: string
  variant?: 'radio'
  type?: 'break' | 'exit_call' | 'join_call'
  background?: boolean
  curValue?: string
  style?: ModalStyles
  onCancel: MouseEventHandler<HTMLDivElement>
  onOk: MouseEventHandler<HTMLDivElement>
  onRadioChange?: ChangeEventHandler<HTMLInputElement>
}

const Modal = ({
  onCancel,
  title,
  body,
  type,
  variant,
  onOk,
  onRadioChange,
  style,
  curValue,
}: ModalProps) => {
  const handleCancel: MouseEventHandler<HTMLDivElement> = React.useCallback(
    (e) => {
      const target = e.target as Element
      target.classList[0] === 'modal-background' && onCancel(e)
    },
    [onCancel]
  )

  return (
    <div className="modal" onClick={handleCancel} style={style}>
      <div className="modal-container">
        <div className="modal-articles">
          <p className="modal-articles-title">{title}</p>
          <p className="modal-articles-body">{body}</p>
        </div>

        {!!type && (variant === 'radio') && (
          <InnerModalRadio
            onChange={onRadioChange}
            curValue={curValue}
            type={type}
          />
        )}

        <div className="modal-btns-group">
          <div className="modal-btns-wrapper">
            <Button text="Ок" onClick={onOk} />
          </div>

          <div className="modal-btns-wrapper">
            <Button text="Отмена" type="outline" onClick={onCancel} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal
