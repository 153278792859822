import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { getAllReasons, sendRequest } from '../requests/header'
import {
  AgentRequestNotification,
  AgentState,
  StateChangeReasonResponse,
} from '../../api/data-contracts'
import { getAgentState } from '../requests/user';

export interface HeaderStore {
  isCollapsed: boolean
  visibilityProfileModal: boolean
  visibilityNotifications: boolean
  agentStateNotify: AgentState['state'] | null
  stateChangeReasons: Array<StateChangeReasonResponse>
  pendingRequests: {
    break_rq: AgentRequestNotification | null
    logout_rq: AgentRequestNotification | null
  }
}

const initialState: HeaderStore = {
  isCollapsed: false,
  visibilityProfileModal: false,
  visibilityNotifications: false,
  agentStateNotify: null,
  stateChangeReasons: [],
  pendingRequests: {
    break_rq: null,
    logout_rq: null,
  },
}

export const headerSlice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    dropHeaderSlice: () => initialState,
    setCollapse(
      state: HeaderStore,
      { payload }: PayloadAction<HeaderStore['isCollapsed']>
    ) {
      state.isCollapsed = payload
    },
    setVisibilityProfileModal(
      state: HeaderStore,
      { payload }: PayloadAction<HeaderStore['visibilityProfileModal']>
    ) {
      state.visibilityProfileModal = payload
    },
    setVisibilityNotifications(
      state: HeaderStore,
      { payload }: PayloadAction<HeaderStore['visibilityNotifications']>
    ) {
      state.visibilityNotifications = payload
    },
    setAgentStateNotify(
      state: HeaderStore,
      { payload }: PayloadAction<HeaderStore['agentStateNotify']>
    ) {
      state.agentStateNotify = payload
    },
    deleteBreakRequest(state: HeaderStore) {
      state.pendingRequests.break_rq = null
    },
    deleteLogoutRequest(state: HeaderStore) {
      state.pendingRequests.logout_rq = null
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAgentState.fulfilled, (state, { payload }) => {
      state.agentStateNotify = payload?.state ?? null;
    })
    builder.addCase(getAllReasons.fulfilled, (state, { payload }) => {
      state.stateChangeReasons = payload
    })
    builder.addCase(sendRequest.fulfilled, (state, { payload }) => {
      switch (payload.type) {
        case 'BREAK_REQUEST':
          state.pendingRequests.break_rq = payload
          break
        case 'LOGOUT_REQUEST':
          state.pendingRequests.logout_rq = payload
          break
      }
    })
  },
})

export const {
  setCollapse,
  setVisibilityNotifications,
  setVisibilityProfileModal,
  setAgentStateNotify,
  deleteBreakRequest,
  deleteLogoutRequest,
  dropHeaderSlice,
} = headerSlice.actions
export const headerReducer = headerSlice.reducer
