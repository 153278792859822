import { useEffect, useMemo } from 'react'
import { useSubscription } from 'react-stomp-hooks'
import { useAppDispatch, useAppSelector } from '../../store/hooks/hook'
import { stompSelector } from '../../store/selectors/stomp'
import { setJtapiAuthorizeStatus } from '../../store/slices/stompSlice'
import { userSelector } from '../../store/selectors/user'
import {
  getAgentState,
  getSupervisorData,
  getUserData,
  loginJtapi,
  logoff,
} from '../../store/requests/user'
import { useApi } from '../../store/hooks/request'
import { addLoginNotification } from '../../store/slices/userSlice'
import {
  setAgentStateNotify,
  setVisibilityNotifications,
} from '../../store/slices/headerSlice'

export default function useJtapiRegistrationStatus() {
  const dispatch = useAppDispatch()
  const { api, logoffApi } = useApi()

  const { connected, jtapiAuthorize } = useAppSelector(stompSelector)
  const { agentId, role, isLogouting } = useAppSelector(userSelector)

  const isUser = useMemo(() => role?.[0] === 'ROLE_USER', [role])

  // reset agent state to initial on start
  useEffect(() => {
    if (isUser) {
      dispatch(setAgentStateNotify(null))
    } else {
      dispatch(getSupervisorData({ api, agentId: agentId ?? '' }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUser])

  useSubscription(
    isUser ? `/agent/${agentId}/registration/jtapi` : [],
    (mes) => {
      const message = JSON.parse(mes.body).message

      if (message === 'SUCCESS') {
        dispatch(setJtapiAuthorizeStatus(true))
      } else {
        dispatch(
          addLoginNotification({
            icon: 'info',
            id: Date.now(),
            title: 'Ошибка',
            text: 'Серверная ошибка обратитесь к администратору',
          })
        )
        dispatch(setVisibilityNotifications(true))
        dispatch(logoff({ logoffApi }))
      }
    }
  )

  if (!isUser) {
    dispatch(setJtapiAuthorizeStatus(true))
  }

  useEffect(() => {
    if (isUser && !jtapiAuthorize && !isLogouting) {
      dispatch(loginJtapi({ api }))
    }

    if (isUser && jtapiAuthorize && connected) {
      dispatch(
        getUserData({
          api,
          agentId: agentId ?? '',
        })
      )

      dispatch(
        getAgentState({
          api,
          agentId: agentId ?? '',
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connected, jtapiAuthorize, agentId, isUser])

  return { jtapiAuthorize }
}
