import React from 'react'

import { useAppSelector } from '../../store/hooks/hook'
import { userSelector } from '../../store/selectors/user'

import ViewWithScript from '../../components/ViewWithScript/ViewWithScript'

import ShiftSummary from './ShiftSummary/ShiftSummary'
import CallContent from './CallContent/CallContent'
import Header from './Super/Header/Header'
import Content from './Super/Content/Content'

export default function Call() {
  const { role } = useAppSelector(userSelector)
  const isRoleUser = role?.[0] === 'ROLE_USER'

  return (
    <ViewWithScript
      SubHeaderComponent={isRoleUser ? <ShiftSummary /> : <Header />}
      ContentComponent={isRoleUser ? <CallContent /> : <Content />}
    />
  )
}
