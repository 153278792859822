import { createAsyncThunk } from '@reduxjs/toolkit'
import { Api } from '../../api/Api'
import { AgentRequestNotification } from '../../api/data-contracts'
import { addNotification } from '../slices/notificationSlice'
import { JtapiAgentState } from '../states';

interface DefaultRequestType {
  api: Api<unknown>
}

interface AgentStateChangeRequest {
  agentId: string
  stateId: JtapiAgentState[keyof JtapiAgentState]
}

interface ChangeStateType
  extends DefaultRequestType,
    AgentStateChangeRequest {}

export interface ChangeStateRequestType
  extends DefaultRequestType,
    AgentRequestNotification {}

export const changeState = createAsyncThunk(
  'header/changeState',
  async ({ api, agentId, stateId, ...requestData }: ChangeStateType) => {
    await api.changeAgentStateUsingPost(agentId, stateId, requestData)
  }
)

export const sendRequest = createAsyncThunk(
  'header/sendRequest',
  async ({ api, ...requestData }: ChangeStateRequestType, { dispatch }) => {
    try {
      await api.receiveAgentRequestNotificationUsingPost(requestData)

      let title = 'Запрос'
      switch (requestData.type) {
        case 'BREAK_REQUEST':
          title = 'Запрос на перерыв'
          break
        case 'HELP_REQUEST':
          title = 'Запрос на помощь'
          break
        case 'LOGOUT_REQUEST':
          title = 'Запрос на выход из системы'
      }

      dispatch(
        addNotification({
          id: Date.now(),
          icon: 'info',
          title,
          text: 'Запрос был успешно отправлен',
        })
      )
    } catch {
      dispatch(
        addNotification({
          id: Date.now(),
          icon: 'warning',
          title: 'Ошибка',
          text: 'Не удалось отправить запрос',
        })
      )
    }
    return requestData
  }
)

export const getAllReasons = createAsyncThunk(
  'header/getAllReasons',
  async ({ api }: DefaultRequestType) => {
    const { data } = await api.getAllReasonsUsingGet()
    return data
  }
)
