import './SubHeader.sass'
import React from 'react'
import ScriptHeader from '../../Script/ScriptHeader/ScriptHeader'
import { useAppSelector } from '../../../store/hooks/hook'
import { userSelector } from '../../../store/selectors/user'

interface SubHeaderProps {
  SubHeaderComponent: React.ReactNode
}

export default function SubHeader({ SubHeaderComponent }: SubHeaderProps) {
  const { role } = useAppSelector(userSelector)
  return (
    <div className="content-layout-subheader">
      {SubHeaderComponent}

      {role && role[0] === 'ROLE_USER' && <ScriptHeader />}
    </div>
  )
}
