import { useEffect } from 'react'

import { Api } from '../../api/Api'
import { useAppDispatch, useAppSelector } from '../../store/hooks/hook'
import { userSelector } from '../../store/selectors/user'
import {
  getInactivityLogoutSettings,
  logoutJtapi,
} from '../../store/requests/user'
import { changeState } from '../../store/requests/header'
import { setLogoutingStatus } from '../../store/slices/userSlice'
import { JTAPI_AGENT_STATES } from '../../store/states'

export default function useInactivity(
  authorizationStatus: boolean,
  api: Api<unknown>
) {
  const { role, inactivitySettings, agentId } = useAppSelector(userSelector)
  const dispatch = useAppDispatch()

  const logoutAction = () => {
    dispatch(
      changeState({
        api,
        agentId: agentId ?? '',
        stateId: JTAPI_AGENT_STATES.NOT_READY,

        // @TODO: remove?
        // reasonCode: '400',
        // reasonName: 'INACTIVITY',
      })
    )
    dispatch(setLogoutingStatus(true))
    dispatch(logoutJtapi({ api }))
  }

  let logoutTimeout: NodeJS.Timeout | null

  const setTimeouts = () => {
    if (inactivitySettings?.inactivityMaximumTime) {
      logoutTimeout = setTimeout(
        logoutAction,
        inactivitySettings.inactivityMaximumTime * 60000
      )
    }
  }

  const clearTimeouts = () => {
    if (logoutTimeout) {
      clearTimeout(logoutTimeout)
    }
  }

  useEffect(() => {
    if (
      authorizationStatus &&
      !inactivitySettings &&
      role?.[0] === 'ROLE_USER'
    ) {
      dispatch(getInactivityLogoutSettings({ api }))
    }

    const resetTimeout = () => {
      clearTimeouts()
      setTimeouts()
    }

    window.addEventListener('click', resetTimeout)

    setTimeouts()

    return () => {
      window.removeEventListener('click', resetTimeout)
      clearTimeouts()
    }
    // eslint-disable-next-line
  }, [
    role,
    setTimeouts,
    inactivitySettings,
    authorizationStatus,
    clearTimeouts,
  ])
}
