import { useCallback, useEffect } from 'react'
import { useSubscription } from 'react-stomp-hooks'
import { AgentRequestNotification } from '../../api/data-contracts';
import { useAppDispatch, useAppSelector } from '../../store/hooks/hook'
import { userSelector } from '../../store/selectors/user'
import { changeState, getAllReasons } from '../../store/requests/header'
import {
  setAgentStateNotify,
  deleteBreakRequest,
  deleteLogoutRequest,
} from '../../store/slices/headerSlice'
import {
  addNotification,
  pushRequest,
} from '../../store/slices/notificationSlice'
import { logoutJtapi } from '../../store/requests/user'
import { Api } from '../../api/Api'
import { setLogoutingStatus } from '../../store/slices/userSlice'
import { JTAPI_AGENT_STATES } from '../../store/states'

export default function useAgentRequests(
  authorizationStatus: boolean,
  api: Api<unknown>
) {
  const { agentId, role } = useAppSelector(userSelector)
  const dispatch = useAppDispatch()

  const handleDeleteRequest = useCallback(
    (type: string) => {
      if (type === 'BREAK_REQUEST') {
        dispatch(deleteBreakRequest())
      }

      if (type === 'LOGOUT_REQUEST') {
        dispatch(deleteLogoutRequest())
      }
    },
    [dispatch]
  )

  useEffect(() => {
    if (authorizationStatus) {
      dispatch(getAllReasons({ api }))
    }
    // eslint-disable-next-line
  }, [authorizationStatus])

  const isSupervisor = role && role[0] === 'ROLE_SUPERVISOR'

  const url =
    agentId && authorizationStatus && role
      ? isSupervisor
        ? `/agent/${agentId}/notification/agentRequest`
        : `/agent/${agentId}/notification/supervisorResponse`
      : []

  // @TODO: use data-contracts
  interface SupervisorResponseNotification {
    result: string
    type: AgentRequestNotification['type']
  }

  useSubscription(url, (mes) => {
    if (!isSupervisor) {
      const body: SupervisorResponseNotification = JSON.parse(mes.body)

      if (!body.result) {
        let text = 'Ваш запрос был отклонен'

        switch (body.type) {
          case 'BREAK_REQUEST':
            text = 'Ваш запрос на перерыв был отклонен'
            break
          case 'LOGOUT_REQUEST':
            text = 'Ваш запрос на выход был отклонен'
            break
          case 'HELP_REQUEST':
            text = 'Ваш запрос на помощь был отклонен'
            break
        }

        dispatch(
          addNotification({
            id: Date.now(),
            icon: 'message',
            title: 'Уведомление',
            text,
          })
        )

        return handleDeleteRequest(body.type!)
      }

      switch (body.type) {
        case 'BREAK_REQUEST':
          dispatch(
            changeState({
              api,
              agentId: agentId ?? '',
              stateId: JTAPI_AGENT_STATES.NOT_READY

              // @TODO: remove?
              // reasonName: pendingRequests.break_rq?.reasonName,
              // reasonCode: String(pendingRequests.break_rq?.id),
            })
          )
          dispatch(setAgentStateNotify(null))
          break

        case 'LOGOUT_REQUEST':
          dispatch(setLogoutingStatus(true))
          dispatch(logoutJtapi({ api }))
          break
      }

      handleDeleteRequest(body.type!)
    } else {
      dispatch(pushRequest(JSON.parse(mes.body)))
    }
  })
}
