import cn from 'classnames';
import React from 'react'

import { headerSelector } from '../../store/selectors/header'
import { setVisibilityProfileModal } from '../../store/slices/headerSlice'
import { useAppDispatch, useAppSelector } from '../../store/hooks/hook'

import './Avatar.sass'
import { JTAPI_AGENT_STATES } from '../../store/states';

interface AvatarProps {
  hasStatus?: boolean
  forMessages?: boolean
}

export default function Avatar(props: AvatarProps) {
  const { visibilityProfileModal, agentStateNotify } =
    useAppSelector(headerSelector)
  const dispatch = useAppDispatch()

  const handleClick = React.useCallback(
    () => dispatch(setVisibilityProfileModal(!visibilityProfileModal)),
    [dispatch, visibilityProfileModal]
  )

  return (
    <div
      className={cn({
        'avatar-container-messages': props.forMessages,
        'avatar-container': !props.forMessages
      })}
      onClick={!props.forMessages ? handleClick : undefined}
    >
      {props.hasStatus && (
        <div
          className={`avatar-status ${
            agentStateNotify !== JTAPI_AGENT_STATES.NOT_READY && agentStateNotify !== null
              ? 'avatar-status-green'
              : 'avatar-status-yellow'
          }`}
        />
      )}
    </div>
  )
}
