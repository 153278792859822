import React from 'react'
import cn from 'classnames';

import './CallButton.sass'

interface CallButtonProps {
  className?: string
  children?: React.ReactNode
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

export default function CallButton({
  className,
  children,
  onClick,
}: CallButtonProps) {
  return (
    <button
      className={cn(className, 'call-button')}
      type="button"
      onClick={onClick}
    >
      {children}
    </button>
  )
}
