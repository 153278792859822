import React from 'react'

import { useAppSelector } from '../../../store/hooks/hook'
import { scriptSelector } from '../../../store/selectors/script'
import { userSelector } from '../../../store/selectors/user'
import { previewDial } from '../../../store/requests/script'
import { useAppDispatch } from '../../../store/hooks/hook'
import { useApi } from '../../../store/hooks/request'

import RectangleButton from '../Elements/Buttons/RectangleButton/RectangleButton'

import '../Step/Step.sass'

export default function Preview() {
  const { api } = useApi()
  const dispatch = useAppDispatch()

  const { autofillData } = useAppSelector(userSelector)
  const { customer } = useAppSelector(scriptSelector)

  const customerPhone = customer?.customerPhone
  const customData = customer?.customData

  const handlePreviewDial = () => {
    const payload = {
      acdDestCallInfo1: customerPhone!,
      acdDestCallInfo2: autofillData.username,
      campaignId: customer?.campaignId?.toString(),
      customerPhone: autofillData.extension
    };

    dispatch(previewDial({ api, ...payload }))
  }

  return (
    <div className="step">
      <div className="step-header">
        <div className="step-title-name">Превью</div>
      </div>

      <div className="step-summary">
        <div className="step-summary-header">Клиент:</div>
        <div className="step-summary-data">
          {customData?.lastName} {customData?.firstName || ''}
        </div>
      </div>

      <div className="step-summary">
        <div className="step-summary-header">Кампания:</div>
        <div className="step-summary-data">{customer?.campaignTitle}</div>
      </div>

      <div className="step-summary">
        <div className="step-summary-header">Номер телефона:</div>
        <div className="step-summary-data">{customerPhone}</div>
      </div>

      <div className="step-bottom-buttons">
        <RectangleButton
          isBlue
          label="Начать вызов"
          onClick={handlePreviewDial}
        />
      </div>
    </div>
  )
}
