import cn from 'classnames'
import React, { memo, useCallback } from 'react'

import { useAppDispatch, useAppSelector } from '../../store/hooks/hook'
import { scriptSelector } from '../../store/selectors/script'
import {
  setCallbackWindowOpen,
  setCallToEnd,
  setScriptHistoryPop,
  setScriptHistoryPush,
} from '../../store/slices/scriptSlice'

import EndCall from '../Icons/EndCall/EndCall'
import Step from './Step/Step'
import CompletionCodes from './CompletionCodes/CompletionCodes'
import CallButton from './Elements/Buttons/CallButtons/CallButton'
import Callback from './Callback/Callback'
import Preview from './Preview/Preview';

import './Script.sass'

const Script = () => {
  const {
    isExpanded,
    currentScript,
    currentScriptHistory,
    callState,
    completionCodeChoose,
    callbackWindowOpen,
  } = useAppSelector(scriptSelector)

  const dispatch = useAppDispatch()

  const handleSetCallToEnd = () => {
    dispatch(setCallToEnd())
  }

  const handleSetStep = (step: number) => {
    if (step === -1) {
      handleSetCallToEnd()
    }

    dispatch(setScriptHistoryPush(step))
  }

  const handleGoBack = () => {
    const newScriptHistory = [...currentScriptHistory]
    newScriptHistory.splice(newScriptHistory.length - 1, 1)
    dispatch(setScriptHistoryPop())
  }

  const historyIndex = currentScriptHistory[currentScriptHistory?.length - 1] || 0
  const currentStep = currentScript?.steps?.[historyIndex]

  const step = !!currentStep && (
    <Step
      key={`${currentScript?.campaignId}-${currentStep.orderNumber}`}
      step={currentStep}
      setStep={handleSetStep}
      goBack={handleGoBack}
    />
  )

  const isPreview = callState === 'Preview'
  const isInCall = callState === 'Talking'
  const isWrapup = callState === 'Wrapup'
  const isDialing = callState === 'Dialing'

  const handleCloseCallback = useCallback(() => {
    dispatch(setCallbackWindowOpen(false))
  }, [dispatch])

  let stepBlock = <div />

  if (callbackWindowOpen) {
    stepBlock = <Callback onClose={handleCloseCallback} />
  } else if (completionCodeChoose) {
    stepBlock = <CompletionCodes/>
  } else if (isPreview) {
    stepBlock = <Preview />
  } else if (isInCall || isWrapup) {
    stepBlock = step || <div />
  }

  return (
    <div
      className={cn('script-content-wrapper', {
        'script-content-wrapper-expanded': isExpanded,
        'script-content-wrapper-collapsed': !isExpanded,
      })}
      style={{ background: isInCall ? '#BA4433' : '#397FBF' }}
    >
      <div className="script-content-body">{stepBlock}</div>

      {(isInCall || isDialing) && (
        <div className="script-call-buttons">
          <CallButton onClick={handleSetCallToEnd}>
            <EndCall />
          </CallButton>
        </div>
      )}
    </div>
  )
}

export default memo(Script)
