import React, { memo } from 'react'
import cn from 'classnames';

import './InfoItem.sass'

interface InfoItemProps {
  inverted?: boolean
  active?: boolean
  data: string | number
  title: string | number
  small?: boolean
  dataIcon?: React.ReactNode
}

function InfoItem({
  small,
  title,
  data,
  active,
  inverted,
  dataIcon,
}: InfoItemProps) {
  return (
    <div className={cn('info-item', { inverted: inverted })}>
      <div
        className={cn('info-item-data', {
          'info-item-data-active': active,
          inverted: inverted,
          small: small
        })}
      >
        {dataIcon}

        <b>{data}</b>
      </div>

      <div
        className={cn('info-item-title', {
          inverted: inverted,
          small: small
        })}
      >
        {title}
      </div>
    </div>
  )
}

export default memo(InfoItem)
