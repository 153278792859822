import { ChangeEvent, useCallback, useState } from 'react'
import cn from 'classnames'

import { useAppDispatch, useAppSelector } from '../../store/hooks/hook'
import { sendRequest } from '../../store/requests/header'
import { useApi } from '../../store/hooks/request'
import { changeUserStatus } from '../../store/requests/userStatus'
import { headerSelector } from '../../store/selectors/header'
import { stompSelector } from '../../store/selectors/stomp';
import { userSelector } from '../../store/selectors/user'
import { notificationSelector } from '../../store/selectors/notification'
import { setVisibilityNotifications } from '../../store/slices/headerSlice'
import { JTAPI_AGENT_STATES } from '../../store/states'

import Avatar from '../Avatar/Avatar'
import AvayaMainLogo from '../../components/Icons/AvayaMainLogo/AvayaMainLogo'
import Notifier from './Notifier/Notifier'
import Button from '../Button/Button'
import Modal from '../Modal/Modal'
import ProfileModal from '../ProfileModal/ProfileModal'
import NotificationModal from '../Notifications/NotificationModal/NotificationModal'
import Bell from './Bell/Bell'

import './Header.sass'

interface HeaderProps {
  className?: string
}

export default function Header(props: HeaderProps) {
  const dispatch = useAppDispatch()

  const { api } = useApi()

  const {
    isCollapsed,
    visibilityNotifications,
    agentStateNotify,
    visibilityProfileModal,
    stateChangeReasons,
    pendingRequests,
  } = useAppSelector(headerSelector)

  const { connected } = useAppSelector(stompSelector)
  const { notificationList, requestNotifications } =
    useAppSelector(notificationSelector)
  const { role, agentId, agentName } = useAppSelector(userSelector)
  const [pending, setPending] = useState(false)
  const [breakModal, setBreakModal] = useState(false)
  const [breakReason, setBreakReason] = useState<string | null>(null)

  const stateIsNull = agentStateNotify == null
  const stateIsBusy = agentStateNotify === JTAPI_AGENT_STATES.BUSY
  const stateIsReady = agentStateNotify === JTAPI_AGENT_STATES.READY
  const stateIsNotReady = agentStateNotify === JTAPI_AGENT_STATES.NOT_READY
  const stateIsWorkNotReady = agentStateNotify === JTAPI_AGENT_STATES.WORK_NOT_READY

  const isRoleUser = role?.[0] === 'ROLE_USER'
  const isRoleAdmin = role?.[0] === 'ROLE_ADMIN'
  const isBreakRequested = stateIsReady && !!pendingRequests.break_rq

  const handleSendRequest = () => {
    setBreakModal(false)

    const reason = stateChangeReasons.find(
      (item) => item.reasonId === Number(breakReason)
    )

    dispatch(
      sendRequest({
        agentId,
        api,
        agentName,
        type: 'BREAK_REQUEST',
        reasonName: reason?.reasonName!,
        id: reason?.reasonId!,
      })
    )
  }

  const handleChangeBreakRadio = (e: ChangeEvent<HTMLInputElement>) => {
    setBreakReason(e.target.value)
  }

  const changeAgentStateACW = () => {
    if (pending) {
      return
    }

    setPending(true)

    dispatch(
      changeUserStatus({
        api,
        agentId: agentId ?? '',
        stateId: JTAPI_AGENT_STATES.WORK_NOT_READY
      })
    )

    setTimeout(() => {
      setPending(false)
    }, 2000)
  }

  const changeAgentState = () => {
    if (pending) {
      return
    }

    if (!stateIsReady) {
      console.log('Change status to READY')
      dispatch(
        changeUserStatus({
          api,
          agentId: agentId ?? '',
          stateId: JTAPI_AGENT_STATES.READY,
        })
      )
      setPending(true)
    } else {
      console.log('Show break modal')
      setBreakModal(true)
    }

    setTimeout(() => {
      setPending(false)
    }, 2000)
  }

  const handleNotifications = useCallback(() => {
    dispatch(setVisibilityNotifications(!visibilityNotifications))
  }, [dispatch, visibilityNotifications])

  const handleModalCancel = useCallback(() => {
    setBreakModal(false)
  }, [])

  const notifiesCount = notificationList?.length + requestNotifications?.length

  return (
    <div
      className={cn('header', props.className, {
        'header-hidden': isCollapsed,
      })}
    >
      <AvayaMainLogo />
      <div className="right-interface">
        {!isRoleAdmin && (
          <div className="bell-container" onClick={handleNotifications}>
            {notifiesCount > 0 && (
              <div className="bell-notifier">
                <Notifier count={notifiesCount} />
              </div>
            )}

            <div className="bell">
              <Bell />
            </div>
          </div>
        )}

        {isRoleUser && (
          <div className="button-container">
            <Button
              disabled={!connected || pending || isBreakRequested}
              text={stateIsReady ? 'Перерыв' : 'Готов'}
              onClick={changeAgentState}
            />
          </div>
        )}

        {isRoleUser && (
          <div className="button-container button-container-compact">
            <Button
              className="button-container-acw"
              disabled={!connected || pending}
              type="outline"
              text="ACW"
              onClick={changeAgentStateACW}
            />
          </div>
        )}

        {isRoleUser && (
          <div className="status-header">
            <div
              className={cn('status-header-led', {
                'status-header-led-gray': !connected || stateIsNull,
                'status-header-led-green': connected && stateIsReady,
                'status-header-led-yellow': connected && stateIsNotReady,
                'status-header-led-red': connected && (stateIsWorkNotReady || stateIsBusy)
              })}
            />

            <div className="status-header-text">
              {(!connected || stateIsNull) && 'Load'}
              {connected && stateIsReady && 'Ready'}
              {connected && stateIsNotReady && 'AUX'}
              {connected && stateIsBusy && 'Busy'}
              {connected && stateIsWorkNotReady && 'ACW'}
            </div>
          </div>
        )}

        <Avatar />
      </div>

      {breakModal && (
        <Modal
          title="Запрос на перерыв"
          variant="radio"
          type="break"
          body="Укажите причину ухода на перерыв"
          onCancel={handleModalCancel}
          onOk={handleSendRequest}
          onRadioChange={handleChangeBreakRadio}
        />
      )}

      {visibilityProfileModal && <ProfileModal />}

      {visibilityNotifications && !isCollapsed && (
        <NotificationModal view="main" />
      )}
    </div>
  )
}
