import React from 'react'

import Iframe from './Iframe/Iframe'

import './CallContent.sass'

export default function CallContent() {
  return (
    <div className="call-content-wrapper">
      <Iframe />
    </div>
  )
}
