import './Controls.sass'
import React, { useCallback, useEffect, useState } from 'react'
import MicrophoneSlash from './Icons/MicrophoneSlash'
import Microphone from './Icons/Microphone'
import End from './Icons/End'
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks/hook'
import { useApi } from '../../../../../store/hooks/request'
import { endCall } from '../../../../../store/requests/user'
import { userSelector } from '../../../../../store/selectors/user'
import moment from 'moment'

const controls = [
  {
    id: 1,
    icon: MicrophoneSlash,
  },
  {
    id: 3,
    icon: Microphone,
  },
]

export default function Controls() {
  const dispatch = useAppDispatch()
  const { api } = useApi()
  const { listeningAgent } = useAppSelector(userSelector)
  const [curControl, setCurControl] = useState(1)
  const onClick = useCallback((id: number) => setCurControl(id), [])
  const handleEndCall = useCallback(
    () => {
      if (listeningAgent.callStart) {
        dispatch(endCall({ api }))
      }
    },
    // eslint-disable-next-line
    [dispatch, listeningAgent]
  )
  const [time, setTime] = useState<string>('00:00')

  const setCallTime = useCallback((callSeconds: number) => {
    callSeconds > 3600
      ? setTime(moment.utc(callSeconds * 1000).format('hh:mm:ss'))
      : setTime(moment.utc(callSeconds * 1000).format('mm:ss'))
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      if (listeningAgent.callStart) {
        const callStart = moment.unix(listeningAgent.callStart)
        const callSeconds = moment().diff(callStart, 'seconds')
        setCallTime(callSeconds)
      }
    }, 1000)

    if (!listeningAgent.callStart) {
      clearInterval(interval)
      setTime('00:00')
    }

    return () => {
      clearInterval(interval)
      setTime('00:00')
    }
  }, [setCallTime, listeningAgent])

  return (
    <div className="call-controls">
      <div className="call-controls-timer">
        {`${time} – ${listeningAgent.name || 'Агент не подключен'}`}
      </div>

      <div className="call-controls-wrapper">
        <div className="call-controls-modes">
          {controls.map(({ id, icon }) => (
            <div
              key={id}
              className={`call-controls-modes-item${
                id === curControl ? ' call-controls-modes-item-chosen' : ''
              }`}
              onClick={() => onClick(id)}
            >
              {icon({ isActive: id === curControl })}
            </div>
          ))}
        </div>

        <div className="call-controls-end">
          <div className="call-controls-modes-item" onClick={handleEndCall}>
            <End />
          </div>
        </div>
      </div>
    </div>
  )
}
