import { useEffect, useState } from 'react'

import { useAppSelector } from '../../../../store/hooks/hook'
import { scriptSelector } from '../../../../store/selectors/script'
// import { userSelector } from '../../../../store/selectors/user'

import './Iframe.sass'

export default function Iframe() {
  // const { autofillData } = useAppSelector(userSelector)
  const { chosenContactNumber } = useAppSelector(scriptSelector)
  const [host, setHost] = useState('https://ud.okta-solutions.com/crm/#/Login')

  const onFrameLoad = () => {
    // const doc = document.getElementById('iframeId') as HTMLIFrameElement
    // console.log(doc)
    // const loginField = doc?.contentWindow?.document
    //     .querySelectorAll('input.ng-tns-c382-2')
    //     .item(0)
    // const passField = doc?.contentWindow?.document
    //     .querySelectorAll('input.ng-tns-c382-2')
    //     .item(1)
    // const subButton = doc?.contentWindow?.document.querySelector(
    //     'button.ng-tns-c382-2'
    // ) as HTMLButtonElement
    // loginField?.setAttribute('value', autofillData.username as string)
    // passField?.setAttribute('value', autofillData.password as string)
    // subButton.click()
  }

  useEffect(() => {
    if (chosenContactNumber) {
      setHost(
        'https://ud.okta-solutions.com/crm/#/home/unified-search?query_string=' +
          chosenContactNumber?.numberField
      )

      setTimeout(() => {
        const iframe = document.getElementById('iframeId') as HTMLIFrameElement
        const innerIframe = iframe?.contentWindow?.document.body.querySelector(
          'iframe'
        ) as HTMLIFrameElement
        const row = innerIframe?.contentWindow?.document
          .getElementsByClassName('oddListRowS1')
          .item(0)
        const rowChild = row?.children[1]?.children[0]
        const href = rowChild?.getAttribute('href')
        const hrefString = href?.toString()

        if (hrefString !== undefined) {
          setHost(hrefString)
        }
      }, 2200)
    }
  }, [chosenContactNumber])

  return (
    <div className="iframe-container">
      <iframe
        id="iframeId"
        seamless
        title="crm"
        className="iframe-container-iframe"
        src={host}
        onLoad={onFrameLoad}
      />
    </div>
  )
}
