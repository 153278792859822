/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AdminAgentGroupDto,
  AdminAgentProfileDto,
  AdminCampaignDto,
  AdminChatGroupRoomDto,
  AdminChatRoomDto,
  AdminSupervisorAgentGroupLinkRequest,
  AdminSupervisorDto,
  AdminUserDto,
  AdminUserToSaveRequest,
  AgentCallStatus,
  AgentForceLogoutResponse,
  AgentGroupDashboardStateRequest,
  AgentGroupDashboardStateResponse,
  AgentGroupDashboardWidgetStateResponse,
  AgentGroupsResponse,
  AgentGroupWidgetStateRequest,
  AgentGroupWidgetStateResponse,
  AgentJobListResponse,
  AgentJobStatisticsResponse,
  AgentProfileEntity,
  AgentRequestNotification,
  AgentsCompletionCodeStatisticsRequest,
  AgentsCompletionCodeStatisticsResponse,
  AgentStateChangeNotification,
  AgentStatisticsNotification,
  AgentStatusForSupervisor,
  CallbackDestsPomResponse,
  CallbackInfoListResponse,
  CallbackTypesPomResponse,
  CampaignAgentsListResponse,
  CampaignAssignedAgentResponse,
  CampaignCardJobStatisticsResponse,
  CampaignCardOperatorResponse,
  CampaignDetailsResponse,
  CampaignResponse,
  CampaignsCompletionCodeStatisticsRequest,
  CampaignsCompletionCodeStatisticsResponse,
  ChatGroupMessage,
  ChatGroupRoomDto,
  ChatMessage,
  ChatRoom,
  CommonSettingsChangeRequest,
  CommonSettingsResponse,
  CompletionCodeEntity,
  ContactNumberRequest,
  ContactRequest,
  ContactResponse,
  CreateCallbackRequest,
  CrmSettingsResponse,
  DefaultAgentMessageResponse,
  DefaultAgentRequest,
  FluxAgentState,
  InactivityLogoutSettingsResponse,
  JobStatusResponse,
  KeyValuePairPomRequest,
  MakeSupervisorCallToAgentRequest,
  MonoAgentState,
  MonoAgentStateChangeResponse,
  MonoOpcsApiResponseVoid,
  MonoResponseEntitySetTalkEndTimeResponse,
  MonoResponseEntityVoid,
  MoveAgentToAnotherJobRequest,
  MoveAgentToAnotherJobResponse,
  ProcessNotificationRequest,
  ScriptExistsResponse,
  ScriptRequest,
  ScriptResponse,
  SetMultiCustomerDataRequest,
  SetTalkEndTimeRequest,
  StateChangeReasonResponse,
  SupervisorInfoResponse,
  SupervisorRole,
  SupervisorStatisticsRequest,
  Tag,
  WrapupContactRequest,
  WrapupPomUniversalResponse,
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class Api<
  SecurityDataType = unknown
> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags admin-agent-group-controller
   * @name AssignSupervisorUsingPost
   * @summary Назначение группе агентов супервизора
   * @request POST:/api/admin/agent-group/assign-supervisor
   */
  assignSupervisorUsingPost = (
    request: AdminSupervisorAgentGroupLinkRequest,
    params: RequestParams = {}
  ) =>
    this.request<AdminAgentGroupDto, void>({
      path: `/api/admin/agent-group/assign-supervisor`,
      method: 'POST',
      body: request,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags admin-agent-group-controller
   * @name DeleteUsingGet
   * @summary Удаление agent group
   * @request GET:/api/admin/agent-group/delete/{id}
   */
  deleteUsingGet = (id: number, params: RequestParams = {}) =>
    this.request<MonoResponseEntityVoid, void>({
      path: `/api/admin/agent-group/delete/${id}`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags admin-agent-group-controller
   * @name FindAllUsingGet
   * @summary Получить все agent group
   * @request GET:/api/admin/agent-group/find-all
   */
  findAllUsingGet = (params: RequestParams = {}) =>
    this.request<AdminAgentGroupDto[], void>({
      path: `/api/admin/agent-group/find-all`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags admin-agent-group-controller
   * @name SaveOrUpdateUsingPost
   * @summary Создание/редактирование agent group
   * @request POST:/api/admin/agent-group/save-or-update
   */
  saveOrUpdateUsingPost = (
    agentGroup: AdminAgentGroupDto,
    params: RequestParams = {}
  ) =>
    this.request<AdminAgentGroupDto, void>({
      path: `/api/admin/agent-group/save-or-update`,
      method: 'POST',
      body: agentGroup,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags admin-agent-group-controller
   * @name UnassignSupervisorUsingPost
   * @summary Снятие с назначения на группу агентов супервизора
   * @request POST:/api/admin/agent-group/unassign-supervisor
   */
  unassignSupervisorUsingPost = (
    request: AdminSupervisorAgentGroupLinkRequest,
    params: RequestParams = {}
  ) =>
    this.request<AdminAgentGroupDto, void>({
      path: `/api/admin/agent-group/unassign-supervisor`,
      method: 'POST',
      body: request,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags admin-agent-profile-controller
   * @name DeleteUsingGet1
   * @summary Удаление agent profile
   * @request GET:/api/admin/agent-profile/delete/{id}
   */
  deleteUsingGet1 = (id: string, params: RequestParams = {}) =>
    this.request<MonoResponseEntityVoid, void>({
      path: `/api/admin/agent-profile/delete/${id}`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags admin-agent-profile-controller
   * @name FindAllUsingGet1
   * @summary Получить все agent profile
   * @request GET:/api/admin/agent-profile/find-all
   */
  findAllUsingGet1 = (params: RequestParams = {}) =>
    this.request<AdminAgentProfileDto[], void>({
      path: `/api/admin/agent-profile/find-all`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags admin-agent-profile-controller
   * @name SaveOrUpdateUsingPost1
   * @summary Создание/редактирование agent profile
   * @request POST:/api/admin/agent-profile/save-or-update
   */
  saveOrUpdateUsingPost1 = (
    agentProfile: AdminAgentProfileDto,
    params: RequestParams = {}
  ) =>
    this.request<AdminAgentProfileDto, void>({
      path: `/api/admin/agent-profile/save-or-update`,
      method: 'POST',
      body: agentProfile,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags admin-campaign-controller
   * @name DeleteUsingGet2
   * @summary Удаление campaign
   * @request GET:/api/admin/campaign/delete/{id}
   */
  deleteUsingGet2 = (id: number, params: RequestParams = {}) =>
    this.request<MonoResponseEntityVoid, void>({
      path: `/api/admin/campaign/delete/${id}`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags admin-campaign-controller
   * @name FindAllUsingGet2
   * @summary Получить все campaign
   * @request GET:/api/admin/campaign/find-all
   */
  findAllUsingGet2 = (params: RequestParams = {}) =>
    this.request<AdminCampaignDto[], void>({
      path: `/api/admin/campaign/find-all`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags admin-campaign-controller
   * @name SaveOrUpdateUsingPost2
   * @summary Создание/редактирование campaign
   * @request POST:/api/admin/campaign/save-or-update
   */
  saveOrUpdateUsingPost2 = (
    campaign: AdminCampaignDto,
    params: RequestParams = {}
  ) =>
    this.request<AdminCampaignDto, void>({
      path: `/api/admin/campaign/save-or-update`,
      method: 'POST',
      body: campaign,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags admin-chat-group-room-controller
   * @name DeleteUsingGet3
   * @summary Удаление chat group room
   * @request GET:/api/admin/chat-group-room/delete/{id}
   */
  deleteUsingGet3 = (id: number, params: RequestParams = {}) =>
    this.request<MonoResponseEntityVoid, void>({
      path: `/api/admin/chat-group-room/delete/${id}`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags admin-chat-group-room-controller
   * @name FindAllUsingGet3
   * @summary Получить все chat group room
   * @request GET:/api/admin/chat-group-room/find-all
   */
  findAllUsingGet3 = (params: RequestParams = {}) =>
    this.request<AdminChatGroupRoomDto[], void>({
      path: `/api/admin/chat-group-room/find-all`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags admin-chat-group-room-controller
   * @name SaveOrUpdateUsingPost3
   * @summary Создание/редактирование chat group room
   * @request POST:/api/admin/chat-group-room/save-or-update
   */
  saveOrUpdateUsingPost3 = (
    chatGroupRoom: AdminChatGroupRoomDto,
    params: RequestParams = {}
  ) =>
    this.request<AdminChatGroupRoomDto, void>({
      path: `/api/admin/chat-group-room/save-or-update`,
      method: 'POST',
      body: chatGroupRoom,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags admin-chat-room-controller
   * @name DeleteUsingGet4
   * @summary Удаление chat room
   * @request GET:/api/admin/chat-room/delete/{id}
   */
  deleteUsingGet4 = (id: number, params: RequestParams = {}) =>
    this.request<MonoResponseEntityVoid, void>({
      path: `/api/admin/chat-room/delete/${id}`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags admin-chat-room-controller
   * @name FindAllUsingGet4
   * @summary Получить все chat room
   * @request GET:/api/admin/chat-room/find-all
   */
  findAllUsingGet4 = (params: RequestParams = {}) =>
    this.request<AdminChatRoomDto[], void>({
      path: `/api/admin/chat-room/find-all`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags admin-chat-room-controller
   * @name SaveOrUpdateUsingPost4
   * @summary Создание/редактирование chat room
   * @request POST:/api/admin/chat-room/save-or-update
   */
  saveOrUpdateUsingPost4 = (
    chatRoom: AdminChatRoomDto,
    params: RequestParams = {}
  ) =>
    this.request<AdminChatRoomDto, void>({
      path: `/api/admin/chat-room/save-or-update`,
      method: 'POST',
      body: chatRoom,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags admin-common-settings-controller
   * @name ChangeSettingUsingPost
   * @summary Изменение супервизором определенной настройки
   * @request POST:/api/admin/common-settings/change-setting
   */
  changeSettingUsingPost = (
    changeRequest: CommonSettingsChangeRequest,
    params: RequestParams = {}
  ) =>
    this.request<MonoResponseEntityVoid, void>({
      path: `/api/admin/common-settings/change-setting`,
      method: 'POST',
      body: changeRequest,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags admin-common-settings-controller
   * @name GetAllSettingsUsingGet
   * @summary Получение всех настроек администратором
   * @request GET:/api/admin/common-settings/get-all-settings
   */
  getAllSettingsUsingGet = (params: RequestParams = {}) =>
    this.request<CommonSettingsResponse[], void>({
      path: `/api/admin/common-settings/get-all-settings`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags admin-supervisor-controller
   * @name AssignAgentGroupUsingPost
   * @summary Назначение супервизору группы агентов
   * @request POST:/api/admin/supervisor/assign-agent-group
   */
  assignAgentGroupUsingPost = (
    request: AdminSupervisorAgentGroupLinkRequest,
    params: RequestParams = {}
  ) =>
    this.request<AdminSupervisorDto, void>({
      path: `/api/admin/supervisor/assign-agent-group`,
      method: 'POST',
      body: request,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags admin-supervisor-controller
   * @name DeleteUsingGet5
   * @summary Удаление supervisor
   * @request GET:/api/admin/supervisor/delete/{id}
   */
  deleteUsingGet5 = (id: number, params: RequestParams = {}) =>
    this.request<MonoResponseEntityVoid, void>({
      path: `/api/admin/supervisor/delete/${id}`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags admin-supervisor-controller
   * @name FindAllUsingGet5
   * @summary Получить все supervisor
   * @request GET:/api/admin/supervisor/find-all
   */
  findAllUsingGet5 = (params: RequestParams = {}) =>
    this.request<AdminSupervisorDto[], void>({
      path: `/api/admin/supervisor/find-all`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags admin-supervisor-controller
   * @name SaveOrUpdateUsingPost5
   * @summary Создание/редактирование supervisor
   * @request POST:/api/admin/supervisor/save-or-update
   */
  saveOrUpdateUsingPost5 = (
    supervisorDto: AdminSupervisorDto,
    params: RequestParams = {}
  ) =>
    this.request<AdminSupervisorDto, void>({
      path: `/api/admin/supervisor/save-or-update`,
      method: 'POST',
      body: supervisorDto,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags admin-supervisor-controller
   * @name UnassignAgentGroupUsingPost
   * @summary Снятие с супервизора назначения на группу агентов
   * @request POST:/api/admin/supervisor/unassign-agent-group
   */
  unassignAgentGroupUsingPost = (
    request: AdminSupervisorAgentGroupLinkRequest,
    params: RequestParams = {}
  ) =>
    this.request<AdminSupervisorDto, void>({
      path: `/api/admin/supervisor/unassign-agent-group`,
      method: 'POST',
      body: request,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags admin-user-controller
   * @name ClearExtensionUsingGet
   * @summary Очистить user extension
   * @request GET:/api/admin/user/clear-extension/{id}
   */
  clearExtensionUsingGet = (id: string, params: RequestParams = {}) =>
    this.request<MonoResponseEntityVoid, void>({
      path: `/api/admin/user/clear-extension/${id}`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags admin-user-controller
   * @name DeleteUsingGet6
   * @summary Удаление user
   * @request GET:/api/admin/user/delete/{id}
   */
  deleteUsingGet6 = (id: string, params: RequestParams = {}) =>
    this.request<MonoResponseEntityVoid, void>({
      path: `/api/admin/user/delete/${id}`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags admin-user-controller
   * @name FindAllUsingGet6
   * @summary Получить все user
   * @request GET:/api/admin/user/find-all
   */
  findAllUsingGet6 = (params: RequestParams = {}) =>
    this.request<AdminUserDto[], void>({
      path: `/api/admin/user/find-all`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags admin-user-controller
   * @name SaveUsingPost
   * @summary Создание user
   * @request POST:/api/admin/user/save
   */
  saveUsingPost = (
    userToSave: AdminUserToSaveRequest,
    params: RequestParams = {}
  ) =>
    this.request<AdminUserDto, void>({
      path: `/api/admin/user/save`,
      method: 'POST',
      body: userToSave,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags admin-user-controller
   * @name UpdateUsingPost
   * @summary Редактирование user (изменить можно только пароль)
   * @request POST:/api/admin/user/update
   */
  updateUsingPost = (adminUserDto: AdminUserDto, params: RequestParams = {}) =>
    this.request<AdminUserDto, void>({
      path: `/api/admin/user/update`,
      method: 'POST',
      body: adminUserDto,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags agent-state-controller
   * @name FindAllUsingGet7
   * @summary findAll
   * @request GET:/api/agent-state
   */
  findAllUsingGet7 = (params: RequestParams = {}) =>
    this.request<FluxAgentState, void>({
      path: `/api/agent-state`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags agent-state-controller
   * @name FindByIdUsingGet
   * @summary findById
   * @request GET:/api/agent-state/{agentId}
   */
  findByIdUsingGet = (agentId: string, params: RequestParams = {}) =>
    this.request<MonoAgentState, void>({
      path: `/api/agent-state/${agentId}`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags agent-state-controller
   * @name UpdateStateUsingPut
   * @summary updateState
   * @request PUT:/api/agent-state/{agentId}/state/{stateId}
   */
  updateStateUsingPut = (
    agentId: string,
    stateId: number,
    params: RequestParams = {}
  ) =>
    this.request<MonoAgentStateChangeResponse, void>({
      path: `/api/agent-state/${agentId}/state/${stateId}`,
      method: 'PUT',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags agent-campaign-assignment-controller
   * @name GetAgentsForCampaignUsingGet
   * @summary Получить список агентов, назначенных на кампанию
   * @request GET:/api/assignment/get-agents-for-campaign/{campaignName}
   */
  getAgentsForCampaignUsingGet = (
    campaignName: string,
    params: RequestParams = {}
  ) =>
    this.request<CampaignAgentsListResponse, void>({
      path: `/api/assignment/get-agents-for-campaign/${campaignName}`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags agent-campaign-assignment-controller
   * @name GetCampaignForAgentUsingGet
   * @summary Получить имя кампании, на которую назначен агент
   * @request GET:/api/assignment/get-campaign-for-agent/{agentId}
   */
  getCampaignForAgentUsingGet = (agentId: string, params: RequestParams = {}) =>
    this.request<CampaignAssignedAgentResponse, void>({
      path: `/api/assignment/get-campaign-for-agent/${agentId}`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags campaign-card-controller
   * @name GetAllCallbacksByCampaignNameAndJobIdUsingGet
   * @summary Получить все коллбеки для карточки кампании супервизора
   * @request GET:/api/campaign-card/get-all-callbacks-by-campaign-name-and-job-id/{campaignName}/{jobId}
   */
  getAllCallbacksByCampaignNameAndJobIdUsingGet = (
    campaignName: string,
    jobId: number,
    params: RequestParams = {}
  ) =>
    this.request<CallbackInfoListResponse, void>({
      path: `/api/campaign-card/get-all-callbacks-by-campaign-name-and-job-id/${campaignName}/${jobId}`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags campaign-card-controller
   * @name GetAllOperatorsByCampaignNameAndJobIdUsingGet
   * @summary Получить всех операторов для карточки кампании супервизора
   * @request GET:/api/campaign-card/get-all-operators-by-campaign-name-and-job-id/{campaignName}/{jobId}
   */
  getAllOperatorsByCampaignNameAndJobIdUsingGet = (
    campaignName: string,
    jobId: number,
    params: RequestParams = {}
  ) =>
    this.request<CampaignCardOperatorResponse[], void>({
      path: `/api/campaign-card/get-all-operators-by-campaign-name-and-job-id/${campaignName}/${jobId}`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags campaign-card-controller
   * @name GetCampaignCardJobStatisticsByJobIdUsingGet
   * @summary получить карточку кампании супервизора
   * @request GET:/api/campaign-card/get-by-campaign-job-id/{campaignId}/{jobId}
   */
  getCampaignCardJobStatisticsByJobIdUsingGet = (
    campaignId: number,
    jobId: number,
    params: RequestParams = {}
  ) =>
    this.request<CampaignCardJobStatisticsResponse, void>({
      path: `/api/campaign-card/get-by-campaign-job-id/${campaignId}/${jobId}`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags chat-group-controller
   * @name FindChatMessagesUsingGet1
   * @summary Получение всех групповых сообщений по id чата
   * @request GET:/api/chat-group/get-messages/{chatId}
   */
  findChatMessagesUsingGet1 = (chatId: number, params: RequestParams = {}) =>
    this.request<ChatGroupMessage[], void>({
      path: `/api/chat-group/get-messages/${chatId}`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags chat-group-controller
   * @name GetGroupRoomsUsingGet
   * @summary Получение всех групповых комнат по id агента
   * @request GET:/api/chat-group/get-rooms/{agentId}
   */
  getGroupRoomsUsingGet = (agentId: string, params: RequestParams = {}) =>
    this.request<ChatGroupRoomDto[], void>({
      path: `/api/chat-group/get-rooms/${agentId}`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags chat-controller
   * @name GetAgentCallStatusUsingGet
   * @summary Получение статуса активного звонка по id агента
   * @request GET:/api/chat/get-call-status/{agentId}
   */
  getAgentCallStatusUsingGet = (agentId: string, params: RequestParams = {}) =>
    this.request<AgentCallStatus, void>({
      path: `/api/chat/get-call-status/${agentId}`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags chat-controller
   * @name FindChatMessagesUsingGet
   * @summary Получение всех сообщений по id чата
   * @request GET:/api/chat/get-messages/{chatId}
   */
  findChatMessagesUsingGet = (chatId: number, params: RequestParams = {}) =>
    this.request<ChatMessage[], void>({
      path: `/api/chat/get-messages/${chatId}`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags chat-controller
   * @name GetRoomsUsingGet
   * @summary Получение всех комнат по id агента
   * @request GET:/api/chat/get-rooms/{agentId}
   */
  getRoomsUsingGet = (agentId: string, params: RequestParams = {}) =>
    this.request<ChatRoom[], void>({
      path: `/api/chat/get-rooms/${agentId}`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags common-settings-controller
   * @name GetCrmSettingsUsingGet
   * @summary Получение настроек Suite-crm
   * @request GET:/api/common-settings/get-crm-settings
   */
  getCrmSettingsUsingGet = (params: RequestParams = {}) =>
    this.request<CrmSettingsResponse, void>({
      path: `/api/common-settings/get-crm-settings`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags common-settings-controller
   * @name GetInactivityLogoutSettingsUsingGet
   * @summary Получение настроек логаута по неактивности
   * @request GET:/api/common-settings/get-inactivity-logout-settings
   */
  getInactivityLogoutSettingsUsingGet = (params: RequestParams = {}) =>
    this.request<InactivityLogoutSettingsResponse, void>({
      path: `/api/common-settings/get-inactivity-logout-settings`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags completion-code-controller
   * @name GetAllUsingGet
   * @summary Получить все коды завершения
   * @request GET:/api/completion-code/find-all
   */
  getAllUsingGet = (params: RequestParams = {}) =>
    this.request<CompletionCodeEntity[], void>({
      path: `/api/completion-code/find-all`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags completion-code-controller
   * @name GetAllByCampaignIdUsingGet
   * @summary Получить все коды завершения по campaignId
   * @request GET:/api/completion-code/findAllByCampaignId/{campaignId}
   */
  getAllByCampaignIdUsingGet = (
    campaignId: number,
    params: RequestParams = {}
  ) =>
    this.request<CompletionCodeEntity[], void>({
      path: `/api/completion-code/findAllByCampaignId/${campaignId}`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags suite-crm-controller
   * @name AddContactUsingPost
   * @summary Добавить контакт
   * @request POST:/api/crm/suite/contact/add-contact
   */
  addContactUsingPost = (
    contactRequest: ContactRequest,
    params: RequestParams = {}
  ) =>
    this.request<MonoResponseEntityVoid, void>({
      path: `/api/crm/suite/contact/add-contact`,
      method: 'POST',
      body: contactRequest,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags suite-crm-controller
   * @name DeleteContactByContactIdUsingDelete
   * @summary Удалить контакт по Id
   * @request DELETE:/api/crm/suite/contact/delete-contact-by-contact-id/{id}
   */
  deleteContactByContactIdUsingDelete = (
    id: number,
    params: RequestParams = {}
  ) =>
    this.request<MonoResponseEntityVoid, void>({
      path: `/api/crm/suite/contact/delete-contact-by-contact-id/${id}`,
      method: 'DELETE',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags suite-crm-controller
   * @name DeleteContactByCrmIdUsingDelete
   * @summary Удалить контакт по Id из CRM
   * @request DELETE:/api/crm/suite/contact/delete-contact-by-crm-id/{id}
   */
  deleteContactByCrmIdUsingDelete = (id: number, params: RequestParams = {}) =>
    this.request<MonoResponseEntityVoid, void>({
      path: `/api/crm/suite/contact/delete-contact-by-crm-id/${id}`,
      method: 'DELETE',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags suite-crm-controller
   * @name GetAllContactsUsingGet
   * @summary Получить все контакты
   * @request GET:/api/crm/suite/contact/get-all-contacts
   */
  getAllContactsUsingGet = (params: RequestParams = {}) =>
    this.request<ContactResponse[], void>({
      path: `/api/crm/suite/contact/get-all-contacts`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags suite-crm-controller
   * @name GetContactByContactIdUsingGet
   * @summary Получить контакт по id контакта
   * @request GET:/api/crm/suite/contact/get-contact-by-contact-id/{id}
   */
  getContactByContactIdUsingGet = (id: number, params: RequestParams = {}) =>
    this.request<ContactResponse, void>({
      path: `/api/crm/suite/contact/get-contact-by-contact-id/${id}`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags suite-crm-controller
   * @name GetContactByContactNameUsingGet
   * @summary Получить контакт по имени
   * @request GET:/api/crm/suite/contact/get-contact-by-contact-name/{name}
   */
  getContactByContactNameUsingGet = (
    name: string,
    params: RequestParams = {}
  ) =>
    this.request<ContactResponse, void>({
      path: `/api/crm/suite/contact/get-contact-by-contact-name/${name}`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags suite-crm-controller
   * @name GetContactByContactPhoneUsingGet
   * @summary Получить контакт по номеру телефона
   * @request GET:/api/crm/suite/contact/get-contact-by-contact-phone/{phone}
   */
  getContactByContactPhoneUsingGet = (
    phone: string,
    params: RequestParams = {}
  ) =>
    this.request<ContactResponse, void>({
      path: `/api/crm/suite/contact/get-contact-by-contact-phone/${phone}`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags suite-crm-controller
   * @name GetContactByContactSurnameUsingGet
   * @summary Получить контакт по фамилии
   * @request GET:/api/crm/suite/contact/get-contact-by-contact-surname/{surname}
   */
  getContactByContactSurnameUsingGet = (
    surname: string,
    params: RequestParams = {}
  ) =>
    this.request<ContactResponse, void>({
      path: `/api/crm/suite/contact/get-contact-by-contact-surname/${surname}`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags suite-crm-controller
   * @name GetContactByCrmIdUsingGet
   * @summary Получить контакт по id из CRM
   * @request GET:/api/crm/suite/contact/get-contact-by-crm-id/{id}
   */
  getContactByCrmIdUsingGet = (id: number, params: RequestParams = {}) =>
    this.request<ContactResponse, void>({
      path: `/api/crm/suite/contact/get-contact-by-crm-id/${id}`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags suite-crm-controller
   * @name UpdateContactUsingPost
   * @summary Обновить контакт
   * @request POST:/api/crm/suite/contact/update-contact
   */
  updateContactUsingPost = (
    contactRequest: ContactRequest,
    params: RequestParams = {}
  ) =>
    this.request<MonoResponseEntityVoid, void>({
      path: `/api/crm/suite/contact/update-contact`,
      method: 'POST',
      body: contactRequest,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags dashboard-controller
   * @name GetAgentGroupsUsingGet
   * @summary Получить список групп агентов
   * @request GET:/api/dashboard/get-agent-groups
   */
  getAgentGroupsUsingGet = (params: RequestParams = {}) =>
    this.request<AgentGroupsResponse, void>({
      path: `/api/dashboard/get-agent-groups`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags dashboard-controller
   * @name GetDashboardStateByGroupIdUsingGet
   * @summary Получить dashboard state по Id группы
   * @request GET:/api/dashboard/get-dashboard-state/{agetGroupId}
   */
  getDashboardStateByGroupIdUsingGet = (
    agetGroupId: number,
    params: RequestParams = {}
  ) =>
    this.request<AgentGroupDashboardStateResponse, void>({
      path: `/api/dashboard/get-dashboard-state/${agetGroupId}`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags dashboard-controller
   * @name GetDashboardWidgetStateUsingGet
   * @summary Получить dashboard и widget state по Id группы
   * @request GET:/api/dashboard/get-dashboard-widget-state/{agentGroupId}
   */
  getDashboardWidgetStateUsingGet = (
    agentGroupId: number,
    params: RequestParams = {}
  ) =>
    this.request<AgentGroupDashboardWidgetStateResponse, void>({
      path: `/api/dashboard/get-dashboard-widget-state/${agentGroupId}`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags dashboard-controller
   * @name GetWidgetStateByGroupIdUsingGet
   * @summary Получить widget state по Id группы
   * @request GET:/api/dashboard/get-widget-state/{agentGroupId}
   */
  getWidgetStateByGroupIdUsingGet = (
    agentGroupId: number,
    params: RequestParams = {}
  ) =>
    this.request<AgentGroupWidgetStateResponse, void>({
      path: `/api/dashboard/get-widget-state/${agentGroupId}`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags dashboard-controller
   * @name SetDashboardStateByGroupIdUsingPost
   * @summary Изменить dashboard state по Id группы
   * @request POST:/api/dashboard/set-dashboard-state
   */
  setDashboardStateByGroupIdUsingPost = (
    request: AgentGroupDashboardStateRequest,
    params: RequestParams = {}
  ) =>
    this.request<AgentGroupDashboardStateResponse, void>({
      path: `/api/dashboard/set-dashboard-state`,
      method: 'POST',
      body: request,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags dashboard-controller
   * @name SetWidgetStateByGroupIdUsingPost
   * @summary Изменить widget state по Id группы
   * @request POST:/api/dashboard/set-widget-state
   */
  setWidgetStateByGroupIdUsingPost = (
    request: AgentGroupWidgetStateRequest,
    params: RequestParams = {}
  ) =>
    this.request<AgentGroupWidgetStateResponse, void>({
      path: `/api/dashboard/set-widget-state`,
      method: 'POST',
      body: request,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags jtapi-adapter-controller
   * @name OpcsReleaseCallUsingPost
   * @summary opcsReleaseCall
   * @request POST:/api/inner/jtapi/agent/opcs/release-line
   */
  opcsReleaseCallUsingPost = (
    setTalkEndTimeRequest: SetTalkEndTimeRequest,
    params: RequestParams = {}
  ) =>
    this.request<MonoResponseEntitySetTalkEndTimeResponse, void>({
      path: `/api/inner/jtapi/agent/opcs/release-line`,
      method: 'POST',
      body: setTalkEndTimeRequest,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Результат запроса в Stomp топике: /stateChange/resp А также в Stomp топике уведомлений при успешной смене: /stateChange/notify
   *
   * @tags jtapi-adapter-controller
   * @name ChangeAgentStateUsingPost
   * @summary Сменить статус агента
   * @request POST:/api/inner/jtapi/agent/{agentId}/state/{stateId}
   */
  changeAgentStateUsingPost = (
    agentId: string,
    stateId: number,
    params: RequestParams = {}
  ) =>
    this.request<
      MonoAgentStateChangeResponse,
      DefaultAgentMessageResponse | AgentStateChangeNotification | void
    >({
      path: `/api/inner/jtapi/agent/${agentId}/state/${stateId}`,
      method: 'POST',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags jtapi-adapter-controller
   * @name AgentStateByIdUsingPost
   * @summary agentStateById
   * @request POST:/api/inner/jtapi/agents/state
   */
  agentStateByIdUsingPost = (params: RequestParams = {}) =>
    this.request<FluxAgentState, void>({
      path: `/api/inner/jtapi/agents/state`,
      method: 'POST',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags jtapi-adapter-controller
   * @name AgentStateByIdUsingPost1
   * @summary agentStateById
   * @request POST:/api/inner/jtapi/agents/{agentId}/state
   */
  agentStateByIdUsingPost1 = (agentId: string, params: RequestParams = {}) =>
    this.request<MonoAgentState, void>({
      path: `/api/inner/jtapi/agents/${agentId}/state`,
      method: 'POST',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags jtapi-adapter-controller
   * @name GetAgentInfoUsingGet
   * @summary Информация по деталям агента
   * @request GET:/api/jtapi/agent-info/{agentId}
   */
  getAgentInfoUsingGet = (agentId: string, params: RequestParams = {}) =>
    this.request<AgentProfileEntity, void>({
      path: `/api/jtapi/agent-info/${agentId}`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * @description Результат запроса в Stomp топике: /registration/jtapi
   *
   * @tags jtapi-adapter-controller
   * @name LoginUsingGet
   * @summary Логин на AES сервере
   * @request GET:/api/jtapi/agent/login
   */
  loginUsingGet = (
    query?: {
      name?: string
    },
    params: RequestParams = {}
  ) =>
    this.request<MonoResponseEntityVoid, DefaultAgentMessageResponse | void>({
      path: `/api/jtapi/agent/login`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    })
  /**
   * @description Результат запроса Stomp в топике: /deregistration/jtapi
   *
   * @tags jtapi-adapter-controller
   * @name LogoutUsingGet
   * @summary Логаут из AES сервера
   * @request GET:/api/jtapi/agent/logout
   */
  logoutUsingGet = (
    query?: {
      name?: string
    },
    params: RequestParams = {}
  ) =>
    this.request<MonoResponseEntityVoid, DefaultAgentMessageResponse | void>({
      path: `/api/jtapi/agent/logout`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags agent-notification-controller
   * @name ReceiveAgentRequestNotificationUsingPost
   * @summary Эндпоинт для получения уведомлений от агента
   * @request POST:/api/notification/agent/agent-request-notify
   */
  receiveAgentRequestNotificationUsingPost = (
    agentRequestNotification: AgentRequestNotification,
    params: RequestParams = {}
  ) =>
    this.request<MonoResponseEntityVoid, void>({
      path: `/api/notification/agent/agent-request-notify`,
      method: 'POST',
      body: agentRequestNotification,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags agent-notification-controller
   * @name GetAllReasonsUsingGet
   * @summary Получение всех причин изменения стейта
   * @request GET:/api/notification/agent/get-all-reasons
   */
  getAllReasonsUsingGet = (params: RequestParams = {}) =>
    this.request<StateChangeReasonResponse[], void>({
      path: `/api/notification/agent/get-all-reasons`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags supervisor-notification-controller
   * @name GetAllUnprocessedNotificationsUsingGet
   * @summary Получение списка необработаннх уведомлений по имени супервизора
   * @request GET:/api/notification/supervisor/get-all-unprocessed-notifications-by-supervisor-username/{supervisorUsername}
   */
  getAllUnprocessedNotificationsUsingGet = (
    supervisorUsername: string,
    params: RequestParams = {}
  ) =>
    this.request<AgentRequestNotification[], void>({
      path: `/api/notification/supervisor/get-all-unprocessed-notifications-by-supervisor-username/${supervisorUsername}`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags supervisor-notification-controller
   * @name ReceiveProcessedNotificationAndSendResultToAgentUsingPost
   * @summary Эндпоинт для получения обработанных супервизором уведомлений
   * @request POST:/api/notification/supervisor/process-notification
   */
  receiveProcessedNotificationAndSendResultToAgentUsingPost = (
    processNotificationRequest: ProcessNotificationRequest,
    params: RequestParams = {}
  ) =>
    this.request<MonoResponseEntityVoid, void>({
      path: `/api/notification/supervisor/process-notification`,
      method: 'POST',
      body: processNotificationRequest,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags completion-code-controller
   * @name GetOpcsAllUsingGet
   * @summary Получить все коды завершения из OPCS
   * @request GET:/api/opcs/completion-code/find-all
   */
  getOpcsAllUsingGet = (params: RequestParams = {}) =>
    this.request<CompletionCodeEntity[], void>({
      path: `/api/opcs/completion-code/find-all`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags completion-code-controller
   * @name GetOpcsAllByCampaignIdUsingGet
   * @summary Получить все коды завершения из OPCS по campaignId
   * @request GET:/api/opcs/completion-code/findAllByCampaignId/{campaignId}
   */
  getOpcsAllByCampaignIdUsingGet = (
    campaignId: number,
    params: RequestParams = {}
  ) =>
    this.request<CompletionCodeEntity[], void>({
      path: `/api/opcs/completion-code/findAllByCampaignId/${campaignId}`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags opcs-controller
   * @name SetDataAndDialUsingPost
   * @summary Set customer data and initiate dialing
   * @request POST:/api/opcs/dial-with-data
   */
  setDataAndDialUsingPost = (
    request: SetMultiCustomerDataRequest,
    params: RequestParams = {}
  ) =>
    this.request<MonoOpcsApiResponseVoid, void>({
      path: `/api/opcs/dial-with-data`,
      method: 'POST',
      body: request,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags pom-api-adapter-controller
   * @name FindCampaignByIdUsingGet
   * @summary Краткая информация о компании по id
   * @request GET:/api/pom-api/find-campaign-by-id/{id}
   */
  findCampaignByIdUsingGet = (id: number, params: RequestParams = {}) =>
    this.request<CampaignDetailsResponse, void>({
      path: `/api/pom-api/find-campaign-by-id/${id}`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags pom-api-adapter-controller
   * @name FindCampaignByNameUsingGet
   * @summary Краткая информация о компании по имени
   * @request GET:/api/pom-api/find-campaign-by-name/{name}
   */
  findCampaignByNameUsingGet = (name: string, params: RequestParams = {}) =>
    this.request<CampaignDetailsResponse, void>({
      path: `/api/pom-api/find-campaign-by-name/${name}`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags pom-api-adapter-controller
   * @name FindAllCampaignsUsingGet
   * @summary Список всех компаний
   * @request GET:/api/pom-api/find-campaigns
   */
  findAllCampaignsUsingGet = (params: RequestParams = {}) =>
    this.request<CampaignResponse[], void>({
      path: `/api/pom-api/find-campaigns`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags pom-api-adapter-controller
   * @name GetAgentInfoUsingGet1
   * @summary Информация по деталям агента
   * @request GET:/api/pom-api/get-agent-info/{agent_id}
   */
  getAgentInfoUsingGet1 = (agentId: string, params: RequestParams = {}) =>
    this.request<AgentProfileEntity, void>({
      path: `/api/pom-api/get-agent-info/${agentId}`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags pom-api-adapter-controller
   * @name GetAgentJobListUsingGet
   * @summary Получение списка задач агента
   * @request GET:/api/pom-api/get-agent-job-list/{agent_id}
   */
  getAgentJobListUsingGet = (agentId: string, params: RequestParams = {}) =>
    this.request<AgentJobListResponse, void>({
      path: `/api/pom-api/get-agent-job-list/${agentId}`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags pom-api-adapter-controller
   * @name GetCallBackByCampaignNameUsingPost
   * @summary Информация по коллбэкам кампании для супервизора
   * @request POST:/api/pom-api/get-callback-by-campaign-name/{campaign_name}
   */
  getCallBackByCampaignNameUsingPost = (
    campaignName: string,
    params: RequestParams = {}
  ) =>
    this.request<CallbackInfoListResponse, void>({
      path: `/api/pom-api/get-callback-by-campaign-name/${campaignName}`,
      method: 'POST',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags pom-api-adapter-controller
   * @name GetJobStatusUsingGet
   * @summary Текущий статус задачи
   * @request GET:/api/pom-api/get-job-status/{id}
   */
  getJobStatusUsingGet = (id: number, params: RequestParams = {}) =>
    this.request<JobStatusResponse, void>({
      path: `/api/pom-api/get-job-status/${id}`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags pom-api-adapter-controller
   * @name GetSupervisorUsingGet
   * @summary Информация по супервизору
   * @request GET:/api/pom-api/get-supervisor/{username}
   */
  getSupervisorUsingGet = (username: string, params: RequestParams = {}) =>
    this.request<SupervisorRole, void>({
      path: `/api/pom-api/get-supervisor/${username}`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags pom-api-adapter-controller
   * @name MoveAgentToAnotherJobUsingPost
   * @summary Перенос агента на другую задачу
   * @request POST:/api/pom-api/move-agent-to-another-job
   */
  moveAgentToAnotherJobUsingPost = (
    request: MoveAgentToAnotherJobRequest,
    params: RequestParams = {}
  ) =>
    this.request<MoveAgentToAnotherJobResponse, void>({
      path: `/api/pom-api/move-agent-to-another-job`,
      method: 'POST',
      body: request,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags pom-api-adapter-controller
   * @name PauseJobUsingPost
   * @summary Поставить на паузу задачу
   * @request POST:/api/pom-api/pause-job/{id}
   */
  pauseJobUsingPost = (id: number, params: RequestParams = {}) =>
    this.request<DefaultAgentMessageResponse, void>({
      path: `/api/pom-api/pause-job/${id}`,
      method: 'POST',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags pom-api-adapter-controller
   * @name ResumeJobUsingPost
   * @summary Возобновить задачу
   * @request POST:/api/pom-api/resume-job/{id}
   */
  resumeJobUsingPost = (id: number, params: RequestParams = {}) =>
    this.request<DefaultAgentMessageResponse, void>({
      path: `/api/pom-api/resume-job/${id}`,
      method: 'POST',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags pom-api-adapter-controller
   * @name StopJobUsingPost
   * @summary Остановить задачу
   * @request POST:/api/pom-api/stop-job/{id}
   */
  stopJobUsingPost = (id: number, params: RequestParams = {}) =>
    this.request<DefaultAgentMessageResponse, void>({
      path: `/api/pom-api/stop-job/${id}`,
      method: 'POST',
      format: 'json',
      ...params,
    })
  /**
   * @description Для этого запроса Stomp топик не предусмотрен
   *
   * @tags pom-adapter-controller
   * @name AddToDncUsingGet
   * @summary Добавить контакт в DNC (Do not call) лист
   * @request GET:/api/pom/agent/add-to-dnc
   */
  addToDncUsingGet = (
    query?: {
      name?: string
    },
    params: RequestParams = {}
  ) =>
    this.request<MonoResponseEntityVoid, void>({
      path: `/api/pom/agent/add-to-dnc`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    })
  /**
   * @description Результат запроса в Stomp топике: /create-callback
   *
   * @tags pom-adapter-controller
   * @name CreateCallbackUsingPost
   * @summary Создать коллбек
   * @request POST:/api/pom/agent/create-callback
   */
  createCallbackUsingPost = (
    createCallbackRequest: CreateCallbackRequest,
    params: RequestParams = {}
  ) =>
    this.request<MonoResponseEntityVoid, DefaultAgentMessageResponse | void>({
      path: `/api/pom/agent/create-callback`,
      method: 'POST',
      body: createCallbackRequest,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Результат запроса в Stomp топике: /extend-wrapup
   *
   * @tags pom-adapter-controller
   * @name ExtendWrapupUsingPost
   * @summary Запрос на продление времени заполнения краткого заключения
   * @request POST:/api/pom/agent/extend-wrapup
   */
  extendWrapupUsingPost = (
    agentPomRequest: DefaultAgentRequest,
    params: RequestParams = {}
  ) =>
    this.request<MonoResponseEntityVoid, WrapupPomUniversalResponse | void>({
      path: `/api/pom/agent/extend-wrapup`,
      method: 'POST',
      body: agentPomRequest,
      format: 'json',
      ...params,
    })
  /**
   * @description Результат запроса в Stomp топике: /get-callback-dests-for-type
   *
   * @tags pom-adapter-controller
   * @name GetCallbackDestsForTypeUsingGet
   * @summary Запросить доступные назначения для выбранного типа коллбека
   * @request GET:/api/pom/agent/get-callback-dests-for-type
   */
  getCallbackDestsForTypeUsingGet = (
    query: {
      name?: string
      /** type */
      type: string
    },
    params: RequestParams = {}
  ) =>
    this.request<MonoResponseEntityVoid, CallbackDestsPomResponse | void>({
      path: `/api/pom/agent/get-callback-dests-for-type`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    })
  /**
   * @description Результат запроса в Stomp топике: /get-callback-types
   *
   * @tags pom-adapter-controller
   * @name GetCallbackTypesUsingGet
   * @summary Получить типы коллбеков
   * @request GET:/api/pom/agent/get-callback-types
   */
  getCallbackTypesUsingGet = (
    query?: {
      name?: string
    },
    params: RequestParams = {}
  ) =>
    this.request<MonoResponseEntityVoid, CallbackTypesPomResponse | void>({
      path: `/api/pom/agent/get-callback-types`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    })
  /**
   * @description Результат запроса Stomp в топике: /registration/pom
   *
   * @tags pom-adapter-controller
   * @name LoginUsingGet1
   * @summary Логин на POM сервере
   * @request GET:/api/pom/agent/login
   */
  loginUsingGet1 = (
    query?: {
      name?: string
    },
    params: RequestParams = {}
  ) =>
    this.request<MonoResponseEntityVoid, DefaultAgentMessageResponse | void>({
      path: `/api/pom/agent/login`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    })
  /**
   * @description Для этого запроса Stomp топик не предусмотрен
   *
   * @tags pom-adapter-controller
   * @name LogoutUsingGet1
   * @summary Логаут из POM сервера
   * @request GET:/api/pom/agent/logout
   */
  logoutUsingGet1 = (
    query?: {
      name?: string
    },
    params: RequestParams = {}
  ) =>
    this.request<MonoResponseEntityVoid, void>({
      path: `/api/pom/agent/logout`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    })
  /**
   * @description Для этого запроса Stomp топик не предусмотрен
   *
   * @tags pom-adapter-controller
   * @name ManualCallConnectUsingPost
   * @summary Aгент сообщает о соединении при ручном наборе номера и переходе в состояние разговора
   * @request POST:/api/pom/agent/manual-call-connect
   */
  manualCallConnectUsingPost = (
    agentPomRequest: DefaultAgentRequest,
    params: RequestParams = {}
  ) =>
    this.request<MonoResponseEntityVoid, void>({
      path: `/api/pom/agent/manual-call-connect`,
      method: 'POST',
      body: agentPomRequest,
      format: 'json',
      ...params,
    })
  /**
   * @description Результат запроса в Stomp топике: /release-line
   *
   * @tags pom-adapter-controller
   * @name PreviewCancelUsingPost
   * @summary Агент хочет отклонить запрос на предварительный просмотр
   * @request POST:/api/pom/agent/preview-cancel
   */
  previewCancelUsingPost = (
    agentPomRequest: DefaultAgentRequest,
    params: RequestParams = {}
  ) =>
    this.request<MonoResponseEntityVoid, WrapupPomUniversalResponse | void>({
      path: `/api/pom/agent/preview-cancel`,
      method: 'POST',
      body: agentPomRequest,
      format: 'json',
      ...params,
    })
  /**
   * @description Для этого запроса Stomp топик не предусмотрен
   *
   * @tags pom-adapter-controller
   * @name PreviewDialUsingPost
   * @summary Агент хочет принять запрос на предварительный просмотр
   * @request POST:/api/pom/agent/preview-dial
   */
  previewDialUsingPost = (
    contactNumberRequest: ContactNumberRequest,
    params: RequestParams = {}
  ) =>
    this.request<MonoResponseEntityVoid, void>({
      path: `/api/pom/agent/preview-dial`,
      method: 'POST',
      body: contactNumberRequest,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Результат запроса в Stomp топике: /release-line
   *
   * @tags pom-adapter-controller
   * @name ReleaseLineUsingPost
   * @summary Агент отправляет эту команду, когда агент хочет отключить вызов клиента.
   * @request POST:/api/pom/agent/release-line
   */
  releaseLineUsingPost = (
    agentPomRequest: DefaultAgentRequest,
    params: RequestParams = {}
  ) =>
    this.request<MonoResponseEntityVoid, WrapupPomUniversalResponse | void>({
      path: `/api/pom/agent/release-line`,
      method: 'POST',
      body: agentPomRequest,
      format: 'json',
      ...params,
    })
  /**
   * @description Для этого запроса Stomp топик не предусмотрен
   *
   * @tags pom-adapter-controller
   * @name SetCustomerDetailsUsingPost
   * @summary Задать параметры для клиента
   * @request POST:/api/pom/agent/set-customer-details
   */
  setCustomerDetailsUsingPost = (
    keyValuePairPomRequest: KeyValuePairPomRequest,
    params: RequestParams = {}
  ) =>
    this.request<MonoResponseEntityVoid, void>({
      path: `/api/pom/agent/set-customer-details`,
      method: 'POST',
      body: keyValuePairPomRequest,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Для этого запроса Stomp топик не предусмотрен
   *
   * @tags pom-adapter-controller
   * @name WrapupContactUsingPost
   * @summary Передача краткого заключения по звонку
   * @request POST:/api/pom/agent/wrapup-contact
   */
  wrapupContactUsingPost = (
    wrapupContactRequest: WrapupContactRequest,
    params: RequestParams = {}
  ) =>
    this.request<MonoResponseEntityVoid, void>({
      path: `/api/pom/agent/wrapup-contact`,
      method: 'POST',
      body: wrapupContactRequest,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags script-manger-controller
   * @name AddScriptUsingPost
   * @summary Добавить скрипт
   * @request POST:/api/script/manager/add-script
   */
  addScriptUsingPost = (
    scriptRequest: ScriptRequest,
    params: RequestParams = {}
  ) =>
    this.request<ScriptResponse, void>({
      path: `/api/script/manager/add-script`,
      method: 'POST',
      body: scriptRequest,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags script-manger-controller
   * @name CheckIfScriptExistsByCampaignIdUsingGet
   * @summary Узнать, существует ли скрипт для кампании с указанным id
   * @request GET:/api/script/manager/check-if-script-exists-by-campaign-id/{campaignId}
   */
  checkIfScriptExistsByCampaignIdUsingGet = (
    campaignId: number,
    params: RequestParams = {}
  ) =>
    this.request<ScriptExistsResponse, void>({
      path: `/api/script/manager/check-if-script-exists-by-campaign-id/${campaignId}`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags script-manger-controller
   * @name DeleteScriptUsingDelete
   * @summary Удалить скрипт
   * @request DELETE:/api/script/manager/delete-script/{id}
   */
  deleteScriptUsingDelete = (id: string, params: RequestParams = {}) =>
    this.request<MonoResponseEntityVoid, void>({
      path: `/api/script/manager/delete-script/${id}`,
      method: 'DELETE',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags script-manger-controller
   * @name GetScriptByCampaignIdUsingGet
   * @summary Получить скрипт по id компании
   * @request GET:/api/script/manager/findScriptByCampaignId/{campaignId}
   */
  getScriptByCampaignIdUsingGet = (
    campaignId: number,
    params: RequestParams = {}
  ) =>
    this.request<ScriptResponse, void>({
      path: `/api/script/manager/findScriptByCampaignId/${campaignId}`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags script-manger-controller
   * @name GetScriptByCampaignNameUsingGet
   * @summary Получить скрипт по id
   * @request GET:/api/script/manager/findScriptByCampaignName/{campaignName}
   */
  getScriptByCampaignNameUsingGet = (
    campaignName: string,
    params: RequestParams = {}
  ) =>
    this.request<ScriptResponse, void>({
      path: `/api/script/manager/findScriptByCampaignName/${campaignName}`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags script-manger-controller
   * @name GetScriptByIdUsingGet
   * @summary Получить скрипт по id
   * @request GET:/api/script/manager/findScriptById/{id}
   */
  getScriptByIdUsingGet = (id: string, params: RequestParams = {}) =>
    this.request<ScriptResponse, void>({
      path: `/api/script/manager/findScriptById/${id}`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags script-manger-controller
   * @name GetAllScriptsUsingGet
   * @summary Получить все скрипты
   * @request GET:/api/script/manager/get-all-scripts
   */
  getAllScriptsUsingGet = (params: RequestParams = {}) =>
    this.request<ScriptResponse[], void>({
      path: `/api/script/manager/get-all-scripts`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags script-manger-controller
   * @name LinkScriptTemplateToCampaignUsingPost
   * @summary Привязать скрипт-темплейт к кампании
   * @request POST:/api/script/manager/link-script-template-to-campaign
   */
  linkScriptTemplateToCampaignUsingPost = (
    scriptRequest: ScriptRequest,
    params: RequestParams = {}
  ) =>
    this.request<ScriptResponse, void>({
      path: `/api/script/manager/link-script-template-to-campaign`,
      method: 'POST',
      body: scriptRequest,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags script-manger-controller
   * @name UpdateScriptUsingPut
   * @summary Обновить скрипт
   * @request PUT:/api/script/manager/update-script
   */
  updateScriptUsingPut = (
    scriptRequest: ScriptRequest,
    params: RequestParams = {}
  ) =>
    this.request<MonoResponseEntityVoid, void>({
      path: `/api/script/manager/update-script`,
      method: 'PUT',
      body: scriptRequest,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags statistics-supervisor-controller
   * @name GetStatisticUsingPost
   * @summary Получить статистику по операторам или камапаниям
   * @request POST:/api/statistics/supervisor/get-statistic
   */
  getStatisticUsingPost = (
    supervisorStatisticsRequest: SupervisorStatisticsRequest,
    params: RequestParams = {}
  ) =>
    this.request<AgentJobStatisticsResponse, void>({
      path: `/api/statistics/supervisor/get-statistic`,
      method: 'POST',
      body: supervisorStatisticsRequest,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags statistics-supervisor-controller
   * @name GetStatisticAgentsCompletionCodeUsingPost
   * @summary Список операторов с количеством каждого вида Completion Code за смену
   * @request POST:/api/statistics/supervisor/get-statistic-agents-completion-code
   */
  getStatisticAgentsCompletionCodeUsingPost = (
    agentPomRequest: AgentsCompletionCodeStatisticsRequest,
    params: RequestParams = {}
  ) =>
    this.request<AgentsCompletionCodeStatisticsResponse[], void>({
      path: `/api/statistics/supervisor/get-statistic-agents-completion-code`,
      method: 'POST',
      body: agentPomRequest,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags statistics-supervisor-controller
   * @name GetStatisticByAgentUsingGet
   * @summary Получить статистику по оператору
   * @request GET:/api/statistics/supervisor/get-statistic-by-agent/{agentId}
   */
  getStatisticByAgentUsingGet = (agentId: string, params: RequestParams = {}) =>
    this.request<AgentStatisticsNotification, void>({
      path: `/api/statistics/supervisor/get-statistic-by-agent/${agentId}`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags statistics-supervisor-controller
   * @name GetStatisticCampaignsCompletionCodeUsingPost
   * @summary Список кампаний с количеством каждого вида Completion Code за смену
   * @request POST:/api/statistics/supervisor/get-statistic-campaigns-completion-code
   */
  getStatisticCampaignsCompletionCodeUsingPost = (
    agentPomRequest: CampaignsCompletionCodeStatisticsRequest,
    params: RequestParams = {}
  ) =>
    this.request<CampaignsCompletionCodeStatisticsResponse[], void>({
      path: `/api/statistics/supervisor/get-statistic-campaigns-completion-code`,
      method: 'POST',
      body: agentPomRequest,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags supervisor-call-controller
   * @name EndSupervisorCallUsingGet
   * @summary Завершить звонок. Только при активном звонке.
   * @request GET:/api/supervisor/end-call
   */
  endSupervisorCallUsingGet = (
    query?: {
      name?: string
    },
    params: RequestParams = {}
  ) =>
    this.request<DefaultAgentMessageResponse, void>({
      path: `/api/supervisor/end-call`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags supervisor-controller
   * @name ForceLogoutAgentUsingPost
   * @summary Провести форс логаут агента супервизором
   * @request POST:/api/supervisor/force-logout-agent
   */
  forceLogoutAgentUsingPost = (
    request: DefaultAgentRequest,
    params: RequestParams = {}
  ) =>
    this.request<AgentForceLogoutResponse, void>({
      path: `/api/supervisor/force-logout-agent`,
      method: 'POST',
      body: request,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags supervisor-call-controller
   * @name GetAgentsUsingGet
   * @summary Получить список агентов со статусами
   * @request GET:/api/supervisor/get-agents
   */
  getAgentsUsingGet = (
    query?: {
      name?: string
    },
    params: RequestParams = {}
  ) =>
    this.request<AgentStatusForSupervisor[], void>({
      path: `/api/supervisor/get-agents`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags supervisor-controller
   * @name GetSupervisorInfoUsingGet
   * @summary Получить информацию о супервизоре
   * @request GET:/api/supervisor/get-supervisor-info/{username}
   */
  getSupervisorInfoUsingGet = (username: string, params: RequestParams = {}) =>
    this.request<SupervisorInfoResponse, void>({
      path: `/api/supervisor/get-supervisor-info/${username}`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags supervisor-call-controller
   * @name MakeSupervisorCallToAgentUsingPost
   * @summary Совершить звонок
   * @request POST:/api/supervisor/make-call-to-agent
   */
  makeSupervisorCallToAgentUsingPost = (
    makeSupervisorCallToAgentRequest: MakeSupervisorCallToAgentRequest,
    query?: {
      name?: string
    },
    params: RequestParams = {}
  ) =>
    this.request<DefaultAgentMessageResponse, void>({
      path: `/api/supervisor/make-call-to-agent`,
      method: 'POST',
      query: query,
      body: makeSupervisorCallToAgentRequest,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags supervisor-call-controller
   * @name SwitchCallModeUsingPost
   * @summary Сменить режим звонка. Только при активном звонке. Пока не поддерживается
   * @request POST:/api/supervisor/switch-call-mode
   */
  switchCallModeUsingPost = (params: RequestParams = {}) =>
    this.request<DefaultAgentMessageResponse, void>({
      path: `/api/supervisor/switch-call-mode`,
      method: 'POST',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags tag-controller
   * @name FindAllUsingGet8
   * @summary Получить все теги
   * @request GET:/api/tag/find-all
   */
  findAllUsingGet8 = (params: RequestParams = {}) =>
    this.request<Tag[], void>({
      path: `/api/tag/find-all`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags tag-controller
   * @name FindAllByCampaignIdUsingGet
   * @summary Получить все теги по campaignId
   * @request GET:/api/tag/findAllByCampaignId/{campaignId}
   */
  findAllByCampaignIdUsingGet = (
    campaignId: number,
    params: RequestParams = {}
  ) =>
    this.request<Tag[], void>({
      path: `/api/tag/findAllByCampaignId/${campaignId}`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags tag-controller
   * @name SaveAllUsingPost
   * @summary Сохранение списка тегов без кампании
   * @request POST:/api/tag/save-all
   */
  saveAllUsingPost = (tags: Tag[], params: RequestParams = {}) =>
    this.request<Tag[], void>({
      path: `/api/tag/save-all`,
      method: 'POST',
      body: tags,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags tag-controller
   * @name CreateOrUpdateUsingPost
   * @summary Создание/редактирование тега
   * @request POST:/api/tag/save-or-update
   */
  createOrUpdateUsingPost = (tag: Tag, params: RequestParams = {}) =>
    this.request<Tag, void>({
      path: `/api/tag/save-or-update`,
      method: 'POST',
      body: tag,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
}
