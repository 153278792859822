import React from 'react'
import cn from 'classnames'

import Navigator from './Navigator/Navigator'
import CollapseIn from '../Icons/Navigation/Collapse/CollapseIn'
import CollapseOut from '../Icons/Navigation/Collapse/CollapseOut'
import { headerSelector } from '../../store/selectors/header'
import {
  setCollapse,
  setVisibilityProfileModal,
} from '../../store/slices/headerSlice'
import Avatar from '../Avatar/Avatar'
import { useAppDispatch, useAppSelector } from '../../store/hooks/hook'
import { userSelector } from '../../store/selectors/user'

import './Sidebar.sass'

interface SidebarProps {
  className: string
}

export default function Sidebar(props: SidebarProps) {
  const dispatch = useAppDispatch()
  const { isCollapsed } = useAppSelector(headerSelector)
  const { role } = useAppSelector(userSelector)

  const handleCollapse = React.useCallback(
    () => dispatch(setCollapse(!isCollapsed)),
    [dispatch, isCollapsed]
  )

  React.useEffect(() => {
    dispatch(setVisibilityProfileModal(false))
  }, [dispatch])

  return (
    <div
      className={cn('sidebar', props.className, {
        'sidebar-full': isCollapsed,
      })}
    >
      <div
        className={cn('sidebar-avatar-wrapper', {
          'sidebar-avatar-wrapper-full': !isCollapsed,
        })}
      >
        <Avatar hasStatus={true} />
      </div>

      <Navigator />

      {role?.[0] !== 'ROLE_ADMIN' && (
        <div className="collapse-container" onClick={handleCollapse}>
          {isCollapsed ? <CollapseOut /> : <CollapseIn />}
        </div>
      )}
    </div>
  )
}
