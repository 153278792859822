import React, { memo, useCallback, useEffect, useMemo } from 'react'
import './ChatList.sass'
import DialogPreview from './DialogPreview/DialogPreview'
import { ChatTypes, SelectedChat } from '../../../../store/slices/chatSlice'
import { useAppSelector } from '../../../../store/hooks/hook'
import { userSelector } from '../../../../store/selectors/user'
import { campaignSelector } from '../../../../store/selectors/campaign'
import {
  getLastMessage,
  getLastMessageTimestamp,
  getTimeFromLastMessage,
  sortedMessages,
} from './utils'
import { useApi } from '../../../../store/hooks/request'
import { useDispatch } from 'react-redux'
import { getAgentCallStatusUsing } from '../../../../store/requests/user'

interface ChatListProps {
  selectedChatId: SelectedChat['id']
  chats?: ChatTypes
  onClick: (chatId: SelectedChat['id'], type: SelectedChat['type']) => void
}

function ChatList({ chats, onClick, selectedChatId }: ChatListProps) {
  const { agentId, role } = useAppSelector(userSelector)
  const { campaignInfo } = useAppSelector(campaignSelector)
  const dispatch = useDispatch()
  const { api } = useApi()

  useEffect(() => {
    if (agentId) {
      dispatch(getAgentCallStatusUsing({ agentId, api }))
    }
  }, [agentId, api, dispatch])

  const isSupervisor = useMemo(() => {
    return role![0] === 'ROLE_SUPERVISOR'
  }, [role])

  const oneToOneChatsRender = useCallback(() => {
    return sortedMessages(chats?.oneToOne ?? [])?.map((el) => {
      const username =
        agentId === el.senderId! ? el.recipientName! : el.senderName!
      const lastMessage = getLastMessage(el.messages)

      const lastMessageTimestamp = getLastMessageTimestamp(el.messages)

      const lastMessageTime = getTimeFromLastMessage(lastMessageTimestamp)

      return (
        <DialogPreview
          selected={selectedChatId === el.chatId}
          key={el.chatId}
          username={username ?? el?.senderId}
          group={'Личные'}
          message={lastMessage}
          messageTime={lastMessageTime}
          unreadMessages={0}
          onClick={() => {
            onClick(el.chatId!, 'oneToOne')
          }}
        />
      )
    })
  }, [agentId, chats?.oneToOne, onClick, selectedChatId])

  const groupChatsRender = useCallback(() => {
    const agentChats = sortedMessages(chats?.group ?? [])
      ?.filter((group) => group.campaignName === campaignInfo?.campaignName)
      .map((el) => {
        const username = el.chatName as string

        const lastMessage = getLastMessage(el.messages)

        const lastMessageTimestamp = getLastMessageTimestamp(el.messages)

        const lastMessageTime = getTimeFromLastMessage(lastMessageTimestamp)

        return (
          <DialogPreview
            selected={selectedChatId === el.chatId}
            key={el.chatId}
            username={username}
            group={'Группа'}
            message={lastMessage}
            messageTime={lastMessageTime}
            unreadMessages={10}
            onClick={() => {
              onClick(el.chatId!, 'group')
            }}
          />
        )
      })

    const supervisorChats = sortedMessages(chats?.group ?? []).map((el) => {
      const username = el.chatName!
      const lastMessage = getLastMessage(el.messages)

      const lastMessageTimestamp = getLastMessageTimestamp(el.messages)

      const lastMessageTime = getTimeFromLastMessage(lastMessageTimestamp)

      return (
        <DialogPreview
          selected={selectedChatId === el.chatId}
          key={el.chatId}
          username={username}
          group={'Группа'}
          message={lastMessage}
          messageTime={lastMessageTime}
          unreadMessages={0}
          onClick={() => {
            onClick(el.chatId!, 'group')
          }}
        />
      )
    })
    return isSupervisor ? supervisorChats : agentChats
  }, [chats?.group, onClick, selectedChatId, campaignInfo, isSupervisor])

  return (
    <div className="chat-list-container">
      {oneToOneChatsRender()}
      {groupChatsRender()}
    </div>
  )
}

export default memo(ChatList)
