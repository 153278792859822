export interface JtapiAgentState {
  UNKNOWN: 0
  LOG_IN: 1
  LOG_OUT: 2
  NOT_READY: 3
  READY: 4
  WORK_NOT_READY: 5
  WORK_READY: 6
  BUSY: 7
}

export type JtapiAgentStateValues = JtapiAgentState[keyof JtapiAgentState]

export const JTAPI_AGENT_STATES: Pick<JtapiAgentState, 'NOT_READY' | 'READY' | 'WORK_NOT_READY' | 'BUSY'> = {
  NOT_READY: 3,
  READY: 4,
  WORK_NOT_READY: 5,
  BUSY: 7
}
