import React, { ChangeEventHandler } from 'react'
import './Search.sass'

interface SearchProps {
  autoFocus?: boolean
  onChange: ChangeEventHandler<HTMLInputElement>
  icon?: React.ReactNode
}

export default function Search({
  autoFocus = true,
  onChange,
  icon,
}: SearchProps) {
  return (
    <div className="search-container">
      {icon}
      <input placeholder="Поиск..." autoFocus={autoFocus} onChange={onChange} />
    </div>
  )
}
