import { useEffect } from 'react'
import { getSupervisorNotifications } from '../../store/requests/notifications'
import { useAppSelector } from '../../store/hooks/hook'
import { useAppDispatch } from '../../store/hooks/hook'
import { userSelector } from '../../store/selectors/user'
import { useSubscription } from 'react-stomp-hooks'
import { addNotification } from '../../store/slices/notificationSlice'
import { Api } from '../../api/Api'

interface ErrorInfoResponse {
  message?: string
  description?: string
}

export default function useGetNotifications(
  authorizationStatus: boolean,
  api: Api<unknown>
) {
  const { agentId, role } = useAppSelector(userSelector)
  const dispatch = useAppDispatch()

  const callNotifications = () =>
    dispatch(
      getSupervisorNotifications({
        supervisorUsername: agentId ?? '',
        api,
      })
    )

  const isAvailable = role?.[0] === 'ROLE_SUPERVISOR' && authorizationStatus

  useEffect(() => {
    if (isAvailable) {
      const interval = setInterval(() => {
        callNotifications()
      }, 30000)

      return () => {
        clearInterval(interval)
      }
    }
    // eslint-disable-next-line
  }, [agentId, role])

  useSubscription(
    isAvailable ? `/agent/${agentId}/notification/refresh` : [],
    callNotifications
  )

  useSubscription(
    isAvailable ? `/agent/${agentId}/notification/failed-authorization` : [],
    (mes) => {
      const body = JSON.parse(mes.body)
      dispatch(
        addNotification({
          id: Date.now(),
          icon: 'warning',
          title: `Ошибка (агент - ${body.name})`,
          text: `Ошибка авторизации, логин - ${body.login}`,
        })
      )
    }
  )

  useSubscription(
    authorizationStatus ? `/agent/${agentId}/error-info` : [],
    (mes) => {
      const body: ErrorInfoResponse = JSON.parse(mes.body)

      dispatch(
        addNotification({
          id: Date.now(),
          icon: 'warning',
          title: body.message || '',
          text: body.description || '',
        })
      )
    }
  )
}
