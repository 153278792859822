import React, { useEffect, useState } from 'react'
import moment from 'moment'
import cn from 'classnames'
import { useApi } from '../../../store/hooks/request';
import { previewDial } from '../../../store/requests/script';

import { scriptSelector } from '../../../store/selectors/script'
import { userSelector } from '../../../store/selectors/user';
import { setExpanded } from '../../../store/slices/scriptSlice'
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hook'

import InfoItem from '../../InfoItem/InfoItem'
import ArrowRight from '../../Icons/Arrows/ArrowRight'
import PhoneIncoming from '../../Icons/PhoneIncoming/PhoneIncoming'
import Avatar from '../../Avatar/Avatar'

import './ScriptHeader.sass'

export default function ScriptHeader() {
  const { api } = useApi()

  const { isExpanded, callStart, customer, releaseLineAnswer, callState, previewCallTimer } =
    useAppSelector(scriptSelector)

  const { autofillData, campaignId } = useAppSelector(userSelector)

  const customData = customer?.customData
  const customerPhone = customer?.customerPhone
  const customerName = customData?.lastName + ' ' + customData?.firstName || ''

  const [time, setTime] = useState<string>('00:00')

  const dispatch = useAppDispatch()

  const handleExpand = () => {
    dispatch(setExpanded(!isExpanded))
  }

  const setCallTime = (callSeconds: number) => {
    callSeconds > 3600
      ? setTime(moment.utc(callSeconds * 1000).format('hh:mm:ss'))
      : setTime(moment.utc(callSeconds * 1000).format('mm:ss'))
  }

  const isPreview = callState === 'Preview'
  const isInCall = callState === 'Talking'
  const timerActive = isInCall || !!previewCallTimer

  const headerData = timerActive ? time : 'Ожидание'
  const headerTitle = isInCall ? customerName : 'Клиент не подключен'

  useEffect(() => {
    const callInterval = window.setInterval(() => {
      if (callStart && !previewCallTimer) {
        const callSeconds = moment().diff(moment.unix(callStart), 'seconds')
        setCallTime(callSeconds)
      }
    }, 1000)

    const nextCallInterval = window.setInterval(() => {
      if (isPreview && previewCallTimer) {
        const remainingSeconds = moment
          .unix(previewCallTimer)
          .diff(moment(), 'seconds')
        setCallTime(remainingSeconds)

        if (remainingSeconds < 1) {
          clearInterval(callInterval)
          clearInterval(nextCallInterval)

          const payload = {
            acdDestCallInfo1: customerPhone!,
            acdDestCallInfo2: autofillData.username,
            campaignId: campaignId?.toString(),
            customerPhone: autofillData.extension
          }

          dispatch(previewDial({ api, ...payload }))
        }
      }
    }, 1000)

    if (!callStart && !previewCallTimer) {
      setTime('00:00')
      clearInterval(callInterval)
      clearInterval(nextCallInterval)
    }

    return () => {
      clearInterval(callInterval)
      clearInterval(nextCallInterval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPreview, time, previewCallTimer, releaseLineAnswer])

  return (
    <div
      className="script-header-wrapper"
      style={{ background: !isInCall ? '#286DAD' : '#AD3928' }}
      onClick={handleExpand}
    >
      <div className="script-header-icon">
        <Avatar />
      </div>

      <InfoItem
        inverted
        data={headerData}
        title={headerTitle}
        dataIcon={
          timerActive && <PhoneIncoming className="info-item-data-icon" />
        }
      />

      <div className={cn('script-header-arrow', { rotated: isExpanded })}>
        <ArrowRight />
      </div>
    </div>
  )
}
