import { useSubscription } from 'react-stomp-hooks'
import { AgentStateChangeNotification } from '../../api/data-contracts'
import { useAppDispatch, useAppSelector } from '../../store/hooks/hook'
import { userSelector } from '../../store/selectors/user'
import { deleteBreakRequest, setAgentStateNotify } from '../../store/slices/headerSlice'
import { JTAPI_AGENT_STATES, JtapiAgentStateValues } from '../../store/states'

function mapAgentState(
  agentState: AgentStateChangeNotification['agentState']
): JtapiAgentStateValues {
  if (agentState === 'Ready') {
    return JTAPI_AGENT_STATES.READY
  }

  if (agentState === 'NotReady') {
    return JTAPI_AGENT_STATES.NOT_READY
  }

  if (agentState === 'PendingNotReady') {
    return JTAPI_AGENT_STATES.WORK_NOT_READY
  }

  if (agentState === 'Busy') {
    return JTAPI_AGENT_STATES.BUSY
  }

  throw new Error('Invalid agent state')
}

export default function useStateChangeNotify(authorizationStatus: boolean) {
  const { agentId } = useAppSelector(userSelector)
  const dispatch = useAppDispatch()
  const stateChangeNotifyUrl = authorizationStatus
    ? `/agent/${agentId}/stateChange/notify`
    : []

  useSubscription(stateChangeNotifyUrl, (mes) => {
    const changeNotify: AgentStateChangeNotification = JSON.parse(mes.body)
    const mappedState = mapAgentState(changeNotify.agentState)

    dispatch(setAgentStateNotify(mappedState))

    // reset request break state on state update
    if (mappedState === JTAPI_AGENT_STATES.NOT_READY) {
      dispatch(deleteBreakRequest())
    }
  })
}
